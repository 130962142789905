import {Component, OnDestroy, OnInit} from '@angular/core';
import {ToolbarMessageService} from '../../../core/services/toolbar-message/toolbar-message.service';
import {Subscription} from 'rxjs';
import {delay} from 'rxjs/operators';
import {SubmitStatus} from '../../models/submitStatus';
import {TitleType} from '../../models/titleType';


@Component({
  selector:'app-title',
  templateUrl:'./title.component.html',
  styleUrls:['./title.component.scss']
})
export class TitleComponent implements OnInit, OnDestroy {

  wait:boolean;
  title:string;
  subtitle:string;
  submit:SubmitStatus;
  type:TitleType;
  subscription:Subscription;

  constructor(private toolbarMessageService:ToolbarMessageService) {
  }

  ngOnInit() {
    this.subscription = this.toolbarMessageService.isWaiting()
      .pipe(delay(0))
      .subscribe(wait => this.wait = wait);

    this.subscription = this.toolbarMessageService.getTitle()
      .pipe(delay(0))
      .subscribe(title => this.title = title);

    this.subscription = this.toolbarMessageService.getSubtitle()
      .pipe(delay(0))
      .subscribe(subtitle => this.subtitle = subtitle);

    this.subscription = this.toolbarMessageService.getType()
      .pipe(delay(0))
      .subscribe(type => this.type = type);

    this.subscription = this.toolbarMessageService.getSubmit()
      .pipe(delay(0))
      .subscribe(submit => this.submit = submit);
  }

  onSubmit() {
    this.toolbarMessageService.submit(SubmitStatus.PendingConfirmation);
  }

  ngOnDestroy():void {
    this.subscription.unsubscribe();
  }

  isOnlyTitle():boolean {
    return this.type === TitleType.OnlyTitle;
  }

  isTitleAndBack():boolean {
    return this.type === TitleType.TitleAndBack;
  }
}
