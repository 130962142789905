<div *ngIf="type == 'ver'">
  <i mat-dialog-close class="close icon"></i>
  <span class="cant-comentarios">Comentarios ({{comentarios.length}}) </span>
  <mat-dialog-content style="min-width: 30vw;" class="mat-typography">
    <div class="ui list" *ngIf="comentarios.length>0">
      <div class="ui divider "></div>

      <div class="item" *ngFor="let comentario of comentarios">
        <!--<img class="ui avatar image" src="/images/avatar2/small/rachel.png">-->
        <div class="content">
          <a class="header">{{comentario.user}} <span class="fecha-comentario"> {{ comentario.date | date:'MMM d, y,
              h:mm:ss a' }}</span></a>
          <div class="description">{{comentario.description}}</div>
        </div>
      </div>


    </div>


  </mat-dialog-content>

</div>



<div *ngIf="type == 'crear'">
  <i mat-dialog-close class="close icon"></i>
  <span class="cant-comentarios">Comentarios ({{comentarios.length}}) </span>
  <mat-dialog-content style="margin-top:1em;min-width: 30vw;" class="mat-typography">
    <div class="ui form">
      <div class="field">
        <textarea value="" [(ngModel)]="comentario"  rows="2"></textarea>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button (click)="post()" class="ui  button  primary">
      Agregar comentario
    </button>
  </mat-dialog-actions>

  <div class="ui list" *ngIf="comentarios.length>0">
    <div class="ui divider "></div>

    <div class="item" *ngFor="let comentario of comentarios">
      <!--<img class="ui avatar image" src="/images/avatar2/small/rachel.png">-->
      <div class="content">
        <a class="header">{{comentario.user}} <span class="fecha-comentario"> {{ comentario.date | date:'MMM d, y,
            h:mm:ss a' }}</span></a>
        <div class="description">{{comentario.description}}</div>
      </div>
    </div>

  </div>
</div>


<div *ngIf="type == 'detalle'" class="container-detalle">
  <i mat-dialog-close class="close icon"></i>
  <span class="cant-comentarios">Detalle</span>
  <div class="ui divider "></div>
  <div class="content">
    <a class="header">{{detalle.user}} <span class="fecha-comentario"> {{ detalle.date | date:'MMM d, y,
        h:mm:ss a' }}</span></a>
    <div class="description">{{detalle.detalle}}</div>
  </div>
</div>