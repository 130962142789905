import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
declare var $: any;

@Component({
  selector: 'app-pdf-a-descargar',
  templateUrl: './pdf-a-descargar.component.html',
  styleUrls: ['./pdf-a-descargar.component.scss']
})
export class PdfADescargarComponent implements OnInit {



  /////
  @Input() formularioValue: any;
  @Input() elementosEncontrados: any;
  @Input() fecha: any;
  @Input() planta: any;
  
  constructor( public dialog: MatDialog) {
   
  
  }
  
  ngOnInit(): void {
   
  }

}
