import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as d3 from 'd3';
import ApexCharts from 'apexcharts/dist/apexcharts.common.js';
import { ApiServerService } from 'src/app/core/services/api/api-server.service';
import { UsuarioService } from 'src/app/core/services/usuario/usuario.service';
declare var $: any;

@Component({
    selector: 'app-bar',
    templateUrl: './bar.component.html',
    styleUrls: ['./bar.component.scss']
})
export class BarComponent implements OnInit {
    @Input() filtros;
    results = false;
    @Output() recargarPlantas: EventEmitter<any> = new EventEmitter<any>();

    filtrosInsuficientes = false;
    options = {
        chart: {
            type: 'bar',
            height: '500px',
            width: '100%'
        },
        background: '#F6F8FA',
        series: [{
            name: 'puntaje',
            data: []
        }],
        xaxis: {
            categories: [],
            group: {
                style: {
                    fontSize: '13px',
                    fontWeight: 600,
                },
                groups: [

                ]
            }
        },
        fill: {
            colors: ['#209CC1']
        }
    }
    options2 = {
        chart: {
            type: 'bar',
            height: '500px',
            width: '100%'
        },
        background: '#F6F8FA',
        series: [{
            name: '',
            data: []
        }],
        xaxis: {
            categories: [],
            group: {
                style: {
                    fontSize: '13px',
                    fontWeight: 600,
                },
                groups: []
            },
            labels: {
                formatter: function (value) {
                    // Limitar a 10 caracteres y agregar "..." si excede
                    value = value.split(' - ')[0];
                    value = value.trim();
                    return value;
                }
            }
        },
        tooltip: {
            x: {
                show: false,

            },
            y: {
                formatter: function (value, { seriesIndex, dataPointIndex, w }) {
                    // Obtener el valor completo del eje x desde la categoría
                    return w.config.xaxis.categories[dataPointIndex];
                }
            }
        },
        fill: {
            colors: ['#209CC1']
        },


    }


    constructor(private services: ApiServerService, private usuarioServices: UsuarioService) {

    }

    ngOnInit(): void {
        this.graficar()
    }

    graficar() {
        $('#spinner-load').css("display", "block");

        let plantas = ['*'];
        let canvas = Array.prototype.slice.call(document.getElementsByClassName("apexcharts-canvas"), 0);

        if (canvas.length > 0) {
            for (let element of canvas) {
                element.remove();
            }
        }
        $('#msg').css("visibility", "hidden");

        let filtros = localStorage.getItem('filtroActividades')
        this.filtros = JSON.parse(filtros);
        this.options.series[0].data = []
        this.options.xaxis.categories = []
        this.options.xaxis.group.groups = []
        this.options2.series[0].data = []
        this.options2.xaxis.categories = []
        this.options2.xaxis.group.groups = []

        if (this.filtros.anio || this.filtros.descripcion || this.filtros.planta) {
          

            this.filtrosInsuficientes = false;

            if (this.filtros.descripcion && !this.filtros.planta && !this.filtros.anio) {
                this.services.getAll(1, this.filtros, 2000, plantas).subscribe(res => {
                    this.recargarPlantas.emit(res.utSet);

                    let filtrados = res.data.filter(d => d.Protocol_Id == this.filtros.descripcion);
                    if (filtrados) {

                        this.agruparPorUT(filtrados)
                        var chart = new ApexCharts(document.querySelector("#chart"), this.options2);
                        chart.render();
                        this.results = true;
                        $('#msg').css("visibility", "visible");


                    } else {
                        this.results = false;
                        $('#msg').css("visibility", "visible");

                    }
                    $('#spinner-load').css("display", "none");

                })

            } else
                if (this.filtros.descripcion && this.filtros.planta && !this.filtros.anio) {
                    this.services.getAll(1, this.filtros, 2000, plantas).subscribe(res => {
                        this.recargarPlantas.emit(res.utSet);

                        let filtrados = res.data.filter(d => d.Protocol_Id == this.filtros.descripcion && d.Status.UT.Observed == this.filtros.planta);
                        if (filtrados.length > 0) {
                            filtrados.forEach(f => {
                                let anio = (new Date(f.When)).getFullYear();
                                let index = this.options.xaxis.categories.indexOf(anio);
                                if (index == -1 && f.score != null) {
                                    this.options.xaxis.categories.push((new Date(f.When)).getFullYear())
                                    this.options.series[0].data.push(f.score)
                                } else {
                                    this.options.series[0].data[index] = (f.score)
                                }
                            })
                            var chart = new ApexCharts(document.querySelector("#chart"), this.options);
                            chart.render();
                            this.results = true;
                            $('#msg').css("visibility", "visible");


                        } else {
                            this.results = false;
                            $('#msg').css("visibility", "visible");

                        }
                        $('#spinner-load').css("display", "none");

                    })

                } else
                    if (this.filtros.descripcion && this.filtros.planta && !this.filtros.anio) {
                        this.services.getAll(1, this.filtros, 2000, plantas).subscribe(res => {
                            this.recargarPlantas.emit(res.utSet);

                            let filtrados = res.data.filter(d => d.Protocol_Id == this.filtros.descripcion && d.Status.UT.Observed == this.filtros.planta);
                            if (filtrados.length > 0) {
                                filtrados.forEach(f => {
                                    let anio = (new Date(f.When)).getFullYear();
                                    let index = this.options.xaxis.categories.indexOf(anio);
                                    if (index == -1 && f.score != null) {
                                        this.options.xaxis.categories.push((new Date(f.When)).getFullYear())
                                        this.options.series[0].data.push(f.score)
                                    } else {
                                        this.options.series[0].data[index] = (f.score)
                                    }
                                })
                                var chart = new ApexCharts(document.querySelector("#chart"), this.options);
                                chart.render();
                                this.results = true;
                                $('#msg').css("visibility", "visible");


                            } else {
                                this.results = false;
                                $('#msg').css("visibility", "visible");

                            }
                            $('#spinner-load').css("display", "none");

                        })

                    } else
                        if (this.filtros.anio && this.filtros.planta && !this.filtros.descripcion) {

                            this.services.getAll(1, this.filtros, 2000, plantas).subscribe(res => {
                                this.recargarPlantas.emit(res.utSet);

                                let filtrados = res.data.filter(d => d.Status.UT.Observed == this.filtros.planta && new Date(d.When).getFullYear() == this.filtros.anio);
                                if (filtrados) {
                                    filtrados.forEach(f => {
                                        let protocol = this.formatProtocol(f.Protocol_Id);
                                        let index = this.options.xaxis.categories.indexOf(protocol);
                                        if (index == -1 && f.score != null) {
                                            this.options.xaxis.categories.push(protocol)
                                            this.options.series[0].data.push(f.score)
                                            this.widthCalculate(protocol)
                                        } else {
                                            this.options.series[0].data[index] = (f.score)
                                        }


                                    })
                                    var chart = new ApexCharts(document.querySelector("#chart"), this.options);
                                    chart.render();
                                    this.results = true;
                                    $('#msg').css("visibility", "visible");

                                } else {
                                    this.results = false;
                                    $('#msg').css("visibility", "visible");

                                }

                                $('#spinner-load').css("display", "none");

                            })

                        } else
                            if (this.filtros.planta && !this.filtros.anio && !this.filtros.descripcion) {

                                this.services.getAll(1, this.filtros, 2000, plantas).subscribe(res => {
                                    this.recargarPlantas.emit(res.utSet);

                                    let filtrados = res.data.filter(d => d.Status.UT.Observed == this.filtros.planta);
                                    if (filtrados) {
                                        filtrados.forEach(f => {
                                            let protocol = this.formatProtocol(f.Protocol_Id);
                                            let index = this.options.xaxis.categories.indexOf(protocol);

                                            if (index == -1 && f.score != null) {
                                                this.options.xaxis.categories.push(protocol)
                                                this.widthCalculate(protocol)
                                                this.options.series[0].data.push(f.score)
                                            } else {
                                                this.options.series[0].data[index] = (f.score)
                                            }


                                        })
                                        var chart = new ApexCharts(document.querySelector("#chart"), this.options);
                                        chart.render();
                                        this.results = true;
                                        $('#msg').css("visibility", "visible");

                                    } else {
                                        this.results = false;
                                        $('#msg').css("visibility", "visible");

                                    }

                                    $('#spinner-load').css("display", "none");

                                })

                            } else
                                if (this.filtros.descripcion && this.filtros.planta && this.filtros.anio) {
                                    this.services.getAll(1, this.filtros, 2000, plantas).subscribe(res => {
                                        this.recargarPlantas.emit(res.utSet);

                                        let filtrados = res.data.filter(d => d.Protocol_Id == this.filtros.descripcion && d.Status.UT.Observed == this.filtros.planta && new Date(d.When).getFullYear() == this.filtros.anio);
                                        if (filtrados.length > 0) {
                                            filtrados.forEach(f => {
                                                let anio = (new Date(f.When)).getFullYear();
                                                let index = this.options.xaxis.categories.indexOf(anio);
                                                if (index == -1 && f.score != null) {
                                                    this.options.xaxis.categories.push((new Date(f.When)).getFullYear())
                                                    this.options.series[0].data.push(f.score)
                                                } else {
                                                    this.options.series[0].data[index] = (f.score)
                                                }
                                            })
                                            var chart = new ApexCharts(document.querySelector("#chart"), this.options);
                                            chart.render();
                                            this.results = true;
                                            $('#msg').css("visibility", "visible");


                                        } else {
                                            this.results = false;
                                            $('#msg').css("visibility", "visible");

                                        }
                                        $('#spinner-load').css("display", "none");

                                    })

                                }
                                else
                                    if (this.filtros.anio && !this.filtros.planta && this.filtros.descripcion) {

                                        this.services.getAll(1, this.filtros, 2000, plantas).subscribe(res => {
                                            this.recargarPlantas.emit(res.utSet);

                                            let filtrados = res.data.filter(d => d.Protocol_Id == this.filtros.descripcion && new Date(d.When).getFullYear() == this.filtros.anio);
                                            if (filtrados) {
                                                filtrados.forEach(f => {
                                                    let ot = f.Status.OT.Observed;
                                                    let ut = f.Status.UT.Observed;
                                                    this.options.xaxis.categories.push(ot)
                                                    this.options.series[0].data.push({ y: f.score, x: ut })
                                                })
                                                this.options.series[0].data.forEach(f => {
                                                    let found = this.options.xaxis.group.groups.find(d => d.title == f.x)
                                                    if (found) {
                                                        found.cols++;
                                                    } else {
                                                        this.options.xaxis.group.groups.push({ title: f.x, cols: 1 },)
                                                    }
                                                })
                                                var chart = new ApexCharts(document.querySelector("#chart"), this.options);
                                                chart.render();
                                                this.results = true;
                                                $('#msg').css("visibility", "visible");


                                            } else {
                                                this.results = false;
                                                $('#msg').css("visibility", "visible");

                                            }
                                        })
                                        $('#spinner-load').css("display", "none");

                                    } else {
                                        this.filtrosInsuficientes = true;
                                        $('#msg').css("visibility", "visible");
                                        $('#spinner-load').css("display", "none");

                                    }



        } else {
            this.services.getAll(1, this.filtros, 1, plantas).subscribe(res => {
                this.recargarPlantas.emit(res.utSet);
                $('#spinner-load').css("display", "none");
                $('#msg').css("visibility", "visible");
                this.filtrosInsuficientes = true;
            })

        }





    }
    formatProtocol(Protocol_Id: any) {
        switch (Protocol_Id) {
            case "Evaluación completa":
                return "Evaluación comp";
            case "Generacion electrica":
                return "Generación elect";
            case "Compresion de gas":
                return "Comp de gas";
            default:
                return Protocol_Id;
        }
    }




    widthCalculate(string: string) {
        let widthNumber = (this.options.chart.width == '100%') ? 0 : Number(this.options.chart.width.replace('px', ''))
        let newWidth = (string.length) * 8;
        widthNumber = widthNumber + newWidth;
        this.options.chart.width = widthNumber + 'px';
    }
    agruparPorUT(filtrados) {
        let agrupadosPorUt: any = Object.entries(filtrados.reduce((acc, f) => {
            let ut = f.Status.UT.Observed;
            if (!acc[ut]) {
                acc[ut] = [];
            }
            acc[ut].push(f);
            return acc;
        }, {}));

        agrupadosPorUt.forEach(([ut, array]) => {
            this.options2.xaxis.group.groups.push({ title: ut, cols: array.length },)
            array.forEach(f => {
                let anio = (new Date(f.When)).getFullYear();
                let ot = f.Status.OT.Observed;
                let ut = f.Status.UT.Observed;
                let key = ot + " - " + anio;
                this.options2.xaxis.categories.push(key)
                this.options2.series[0].data.push({ y: f.score, x: ut })
            })
        });

    }
}


