import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { allowedNodeEnvironmentFlags } from 'process';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.activatedRoute.queryParams.subscribe(values => {
      const path = values.path;
      const navigateTo = '/' + path;
   
      if (path) {
        this.router.navigate([navigateTo]);
      }

    });
  }




}