import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {SubmitStatus} from '../../../shared/models/submitStatus';
import {TitleType} from '../../../shared/models/titleType';


@Injectable({
  providedIn:'root'
})
export class ToolbarMessageService {

  private waiteable = new Subject<boolean>();
  private titeable = new Subject<string>();
  private subtiteable = new Subject<string>();
  private typeable = new Subject<TitleType>();
  private submiteable = new Subject<SubmitStatus>();
  private saldoSeteable = new Subject<number>();
  private estadoSeteable = new Subject<boolean>();

  constructor() {
  }

  wait(wait:boolean) {
    this.waiteable.next(wait);
  }

  title(title:string) {
    this.titeable.next(title);
  }

  subtitle(subtitle:string) {
    this.subtiteable.next(subtitle);
  }

  type(type:TitleType) {
    this.typeable.next(type);
  }

  submit(submit:SubmitStatus) {
    this.submiteable.next(submit);
  }

  saldo(saldo:number) {
    this.saldoSeteable.next(saldo);
  }

  estado(estado:boolean) {
    this.estadoSeteable.next(estado);
  }

  isWaiting():Observable<boolean> {
    return this.waiteable.asObservable();
  }

  getTitle():Observable<string> {
    return this.titeable.asObservable();
  }

  getSubtitle():Observable<string> {
    return this.subtiteable.asObservable();
  }

  getType():Observable<TitleType> {
    return this.typeable.asObservable();
  }

  getSubmit():Observable<SubmitStatus> {
    return this.submiteable.asObservable();
  }

  getSaldo():Observable<number> {
    return this.saldoSeteable.asObservable();
  }

  getEstado():Observable<boolean> {
    return this.estadoSeteable.asObservable();
  }
}
