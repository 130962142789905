import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/core/services/usuario/usuario.service';
import { Descripcion } from 'src/app/shared/models/descripciones';
import { FiltroActividades } from 'src/app/shared/models/filtroActividades';
import { ThemePalette } from '@angular/material/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { BarComponent } from '../../components/bar/bar.component';
import { ApiServerService } from 'src/app/core/services/api/api-server.service';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  spinner = true;
  color: ThemePalette = 'primary';
  mode: ProgressBarMode = 'determinate';
  value = 50;
  bufferValue = 75;
  from = 0;
  to = 100;
  listRegistros: any[] = [];
  listFiltrar: any[] = [];
  page = 1;
  estados: any[] = [];
  formDatos: FormGroup;
  descripciones: any[] = [];
  anios: any[] = [];
  mostrarLista = true;
  mostrarCharts = false;
  registros: any[] = [];
  datosPreliminares: any[] = [];
  datosCargaInicial: any[] = [];
  total: number;
  plantas: any[] = [];
  numeroOtFilters: any[] = [];
  @ViewChild("graficos") graficos: BarComponent;
  filtrosCompletados = false;
  filterDescripcion: Descripcion;
  filtros: FiltroActividades;
  totalResults: any[] = [];
  userPlantas;
  pages_total = 0;
  constructor(private form: FormBuilder, private router: Router, private services: ApiServerService, private servicesUsuario: UsuarioService) {
    this.userPlantas = this.servicesUsuario.getPlantas();
    this.estados.push("Finalizado");
    this.estados.push("En proceso");
    $('#spinner-load').css("display", "block");

    let filtros = localStorage.getItem('filtroActividades')
    if (!filtros || filtros == '"null"' || filtros == 'null' || filtros == null) {
      this.filtros = new FiltroActividades();
    } else {
      this.filtros = JSON.parse(filtros);

    }

    this.from = this.filtros.min;
    this.to = this.filtros.max;
    this.formDatos = this.form.group({
      numero: [(this.filtros.numero && this.filtros.numero != '') ? this.filtros.numero : null],
      planta: [(this.filtros.planta && this.filtros.planta != '') ? this.filtros.planta : null],
      descripcion: [(this.filtros.descripcion && this.filtros.descripcion != '') ? this.filtros.descripcion : null],
      estado: [(this.filtros.estado && this.filtros.estado != '') ? this.filtros.estado : null],
      resultado: [(this.filtros.resultado && this.filtros.resultado != '') ? this.filtros.resultado : null],
      fecha: [(this.filtros.fecha) ? this.filtros.fecha : null],
      anio: [(this.filtros.anio && this.filtros.anio != '') ? this.filtros.anio : null],
    });
    this.cargarLista()
  }



  ngOnInit(): void {

  }

  ngAfterViewInit() {


    $('.ui.dropdown').dropdown({
      "clearable": true
    });

    $('.ui.selection.dropdown').dropdown({
      "clearable": true
    });
  }
  recargarPlantas(list: any) {
    this.plantas = list;
  }
  cargarLista() {
    this.spinner = true;
    $('#spinner-load').css("display", "block");
    this.services.getAll(this.page, this.filtros, 11, this.userPlantas).subscribe(res => {
      this.numeroOtFilters = res.otSet;
      this.descripciones = res.protocolList;
      this.plantas = res.utSet;
      this.anios = res.aniosSet;
      this.pages_total = res.totalPageDisponibles;
      this.listRegistros = res.data;
      this.spinner = false;
      $('#spinner-load').css("display", "none");
    })
  }



  detalle(item, descripcion) {
    this.router.navigate(["detalle/" + item.Status.OT.Observed + "/" + descripcion.replace(/ /g, '_') + "/" + item.state]);
  }




  charts() {

    this.formDatos.controls['fecha'].setValue(null)
    this.formDatos.controls['estado'].setValue(null)
    this.formDatos.controls['resultado'].setValue("")
    this.formDatos.controls['numero'].setValue(null)
    this.formDatos.updateValueAndValidity();
    this.to = 100;
    this.from = 0;
    this.page = 1;

    this.filtros.numero = this.formDatos.get('numero').value;
    if (this.formDatos.get('fecha').value) {
      this.filtros.fecha = this.formDatos.get('fecha').value;
    } else {
      this.filtros.fecha = null;
    }
    this.filtros.min = 0;
    this.filtros.max = 100;
    this.filtros.estado = this.formDatos.get('estado').value;
    localStorage.setItem('filtroActividades', JSON.stringify(this.filtros))

    this.mostrarCharts = true;
    this.mostrarLista = false;
  }
  list() {
    this.mostrarCharts = false;
    this.mostrarLista = true;
    this.filtros.planta = (!this.userPlantas.includes(this.filtros.planta)) ? null : this.filtros.planta;
    localStorage.setItem('filtroActividades', JSON.stringify(this.filtros))
    this.formDatos.controls['planta'].setValue(null)
    $('.ui.dropdown').dropdown('restore defaults');

    this.formDatos.updateValueAndValidity();
    this.cargarLista();
  }


  limpiar() {
    this.formDatos.controls['fecha'].setValue(null)
    this.formDatos.controls['descripcion'].setValue(null)
    this.formDatos.controls['estado'].setValue(null)
    this.formDatos.controls['resultado'].setValue("")
    this.formDatos.controls['numero'].setValue(null)
    this.formDatos.controls['planta'].setValue(null)
    this.formDatos.controls['anio'].setValue(null)
    this.to = 100;
    this.from = 0;
    this.page = 1;

    this.filtros.planta = this.formDatos.get('planta').value;
    this.filtros.numero = this.formDatos.get('numero').value;
    if (this.formDatos.get('fecha').value) {
      this.filtros.fecha = this.formDatos.get('fecha').value;
    } else {
      this.filtros.fecha = null;
    }

    this.filtros.min = 0;
    this.filtros.max = 100;
    this.filtros.descripcion = (this.formDatos.get('descripcion').value);
    this.filtros.estado = this.formDatos.get('estado').value;
    localStorage.setItem('filtroActividades', JSON.stringify(this.filtros))

    $('.ui.dropdown').dropdown('restore defaults');
    (this.mostrarLista) ? this.cargarLista() : this.graficos.graficar();
  }

  changePage(number_page: number) {
    this.page = number_page;
    this.cargarLista();
  }

  aplicar() {
    $('#spinner-load').css("display", "block");

    this.filtros.planta = this.formDatos.get('planta').value;
    this.filtros.numero = this.formDatos.get('numero').value;
    if (this.formDatos.get('fecha').value) {
      this.filtros.fecha = this.formDatos.get('fecha').value;
    } else {
      this.filtros.fecha = null;
    }
    this.filtros.anio = this.formDatos.get('anio').value;
    this.filtros.descripcion = this.formDatos.get('descripcion').value;
    this.filtros.estado = this.formDatos.get('estado').value;
    this.filtros.max = this.to;
    this.filtros.min = this.from;

    localStorage.setItem('filtroActividades', JSON.stringify(this.filtros));

    (this.mostrarLista) ? this.cargarLista() : this.graficos.graficar();
  }

  onChangeEvent(event) {
    if (event.to != undefined) {
      this.to = event.to
    }
    if (event.from != undefined) {
      this.from = event.from
    }
  }






}
