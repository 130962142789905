<div class="ui equal width center aligned padded grid">
    <div class="row">
        <div class="column" [style.width]="'100%'">
            <h2 class="subtitle">
                <span *ngIf="filtros.descripcion">{{filtros.descripcion}} </span>
                <span *ngIf="filtros.planta"><span *ngIf="filtros.descripcion"> - </span>{{filtros.planta}} </span>
                <span *ngIf="filtros.anio"><span *ngIf="filtros.planta"> - </span> {{filtros.anio}} </span>
            </h2>
        </div>
        <br>
        <div class="column" id="msg" [style.margin-top]="'2em'" [style.width]="'100%'">
            <div *ngIf="!results && !filtrosInsuficientes" class="ui warning message" [style.margin]="'auto!important'">
                <div class="header">
                    No se encontraron resultados para graficar, intente con otros filtros
                </div>
            </div>
            <div *ngIf="filtrosInsuficientes " class="ui warning message" [style.margin]="'auto!important'">
                <div class="header">
                    Los filtros seleccionados no son suficientes
                </div>
            </div>
            
        </div>
    </div>
</div>
<div id="chart">
</div>