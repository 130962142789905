<div class="container-actividad" *ngIf="(auxiliar.length>0  ||  auxiliarAvisos.length>0) && !infoIgnored">
  <h2 class='title-actividad '>{{actividad.actividad }}</h2>
  <table class="ui single line table">
    <thead>
      <tr>
        <th class="th-item">Item</th>
        <th class="center aligned">Respuesta</th>
        <th class="center aligned no-pdf-cell">Detalles</th>
        <th class="center aligned no-pdf-cell">Foto</th>
        <th class="center aligned no-pdf-cell">Observaciones</th>
        <th class="center aligned no-pdf-cell">Comentarios</th>
        <th class="center aligned no-pdf-cell" colspan="2">Información auxiliar</th>

      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of auxiliar">
        <td style="max-width: 200px !important;white-space: pre-wrap;" class="th-item"
          *ngIf="item.preguntasInfo[0].Text != mantenimiento ">
          {{item.preguntasInfo[0].Text}}
        </td>
        <td style="max-width: 200px !important;white-space: pre-wrap;" class="center aligned"
          *ngIf="item.preguntasInfo[0].Text != mantenimiento ">
          <p class="negrita">
            {{item.preguntasInfo[0].Observed}}</p>
        </td>
        <td style="max-width: 200px !important;white-space: pre-wrap;" class="center aligned no-pdf-cell"
          *ngIf="item.preguntasInfo[0].Text != mantenimiento ">

          <i *ngIf="item.preguntasInfo[0].detalles" class="eye icon font-blue pointer"
            (click)="showDetalles(item.preguntasInfo[0].detalles,item.preguntasInfo[0].Text)"></i>
        </td>
        <td style="max-width: 200px !important;white-space: pre-wrap;" class="center aligned no-pdf-cell"
          *ngIf="item.preguntasInfo[0].Text != mantenimiento ">
          <i *ngIf="item.preguntasInfo[0].fotos" class="camera icon font-blue pointer"
            (click)="showFoto(item.preguntasInfo[0].fotos,item.preguntasInfo[0].Text)"></i>
        </td>
        <td style="max-width: 200px !important;white-space: pre-wrap;" class="center aligned no-pdf-cell"
          *ngIf="item.preguntasInfo[0].Text != mantenimiento ">
          <i *ngIf="item.preguntasInfo[0].observaciones.lista" class="search icon pointer font-blue"
            (click)="showObservaciones(item.preguntasInfo[0].observaciones,item.preguntasInfo[0].Text)"></i>
        </td>
        <td style="max-width: 200px !important;white-space: pre-wrap;" class="center aligned no-pdf-cell"
          *ngIf="item.preguntasInfo[0].Text != mantenimiento">
          <div class="icon-actions">
            <i class="pencil alternate icon font-gray pointer" style="margin-right: 15px;"
              (click)="newComentario(item.nro,item,item.preguntasInfo[0].comentarios)"></i>
          </div>
          <div class="icon-actions">
            <i *ngIf="item.comentariosExiste" class="comment alternate icon pointer font-blue"
              style="margin-left: 5px;" (click)="verComentario(item.nro,item,item.preguntasInfo[0].comentarios)"></i>
          </div>
        </td>
        <td   *ngIf="item.preguntasInfo[0].Text != mantenimiento"  class="center aligned no-pdf-cell">
          <i   [ngClass]="(isRole) ? 'pencil alternate icon font-gray pointer' : 'eye alternate icon font-gray pointer'" style="margin-right: 15px;"
            (click)="newInformacionAux(item)"></i>
            <!-- <i *ngIf="item.tieneInfoAux" class="check icon"></i> -->
        </td>
        <td class="no-pdf-cell"> 
          <i *ngIf="item.tieneInfoAux" class="check icon" ></i>
        </td>


      </tr>
      <tr *ngFor="let item of auxiliarAvisos;index as i">
        <td style="max-width: 200px !important;white-space: pre-wrap;" class="th-item td-gris "
          *ngIf="item.aviso!=='N/A' && ((item.aviso=='M1'  || item.aviso=='M2') || ((item.aviso!='M1'  || item.aviso!='M2') && item.detalles.detalle!='N/A'))">
          {{item.pregunta}}  
        </td>
        <td style="max-width: 200px !important;white-space: pre-wrap;" class="center aligned td-gris "
          *ngIf="item.aviso!=='N/A' && ((item.aviso=='M1'  || item.aviso=='M2') || ((item.aviso!='M1'  || item.aviso!='M2') && item.detalles.detalle!='N/A'))">
          <p class="negrita">
            {{item.aviso}}
          </p>
        </td>
        <td style="max-width: 200px !important;white-space: pre-wrap;" class="center aligned td-gris no-pdf-cell "
          *ngIf="item.aviso!=='N/A' && ((item.aviso=='M1'  || item.aviso=='M2') || ((item.aviso!='M1'  || item.aviso!='M2') && item.detalles.detalle!='N/A'))">
          <i *ngIf="item.detalles.detalle &&  item.detalles.detalle != 'N/A' && item.detalles.detalle != '' "
            class="eye icon font-blue pointer" (click)="showDetalles(item.detalles,item.aviso)"></i>
          <i *ngIf="(item.detalles.detalle &&  (item.detalles.detalle == 'N/A' || item.detalles.detalle == '' ) )"
            class=" exclamation triangle icon font-red pointer" (click)="showDetalles(item.detalles,item.pregunta)">
          </i>
        </td>
        <td style="max-width: 200px !important;white-space: pre-wrap;" class="center aligned td-gris no-pdf-cell"
          *ngIf="item.aviso!=='N/A' && ((item.aviso=='M1'  || item.aviso=='M2') || ((item.aviso!='M1'  || item.aviso!='M2') && item.detalles.detalle!='N/A'))">
          <i *ngIf="item.fotos" class="camera icon font-blue pointer" (click)="showFoto(item.fotos,item.Text)"></i>
        </td>
       
        <td style="max-width: 200px !important;white-space: pre-wrap;" class="center aligned td-gris no-pdf-cell"
          *ngIf="item.aviso!=='N/A' && ((item.aviso=='M1'  || item.aviso=='M2') || ((item.aviso!='M1'  || item.aviso!='M2') && item.detalles.detalle!='N/A'))">
          <i *ngIf="item.observaciones.lista && i==0" class="search icon pointer font-blue"
            (click)="showObservaciones(item.observaciones,item.Text)"></i>
        </td>
      
        <td style="max-width: 200px !important;white-space: pre-wrap;" class="center aligned  td-gris no-pdf-cell"
          *ngIf="item.aviso!=='N/A' && ((item.aviso=='M1'  || item.aviso=='M2') || ((item.aviso!='M1'  || item.aviso!='M2') && item.detalles.detalle!='N/A'))">
          <div
            class="icon-actions">
            <i class="pencil alternate icon font-gray pointer" style="margin-right: 15px;"
              (click)="newComentario(item.nro,item,item.comentarios)"></i>
          </div>
          <div class="icon-actions">
            <i *ngIf="item.comentariosExiste"  class="comment alternate icon pointer font-blue" style="margin-left: 5px;"
              (click)="verComentario(item.nro,item,item.comentarios)"></i>
          </div>
        </td>
        <td style="max-width: 200px !important;white-space: pre-wrap;" class="center aligned td-gris no-pdf-cell">
          <i [ngClass]="(isRole) ? 'pencil alternate icon font-gray pointer' : 'eye alternate icon font-gray pointer'"  style="margin-right: 15px;"
            (click)="newInformacionAux(item)"></i>
          
        </td>
        <td class="td-gris no-pdf-cell" > 
          <i *ngIf="item.tieneInfoAux" class="check icon" ></i>
        </td>



      </tr>
    </tbody>
  </table>


</div>
<div class="container-actividad" *ngIf="infoIgnored">

  <table class="ui celled table" *ngIf="infoIgnored">
    <thead>
      <tr>
        <th>Descripción</th>
        <th>Nº de OT</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style="max-width: 200px !important;white-space: pre-wrap;" data-label="">{{infoIgnored.Titulo.Observed}}
        </td>
        <td style="max-width: 200px !important;white-space: pre-wrap;" class=" negrita">
          {{infoIgnored.Descripcion.Observed}}</td>
      </tr>
    </tbody>
  </table>
</div>