import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ApiServerService } from 'src/app/core/services/api/api-server.service';
import { Service } from 'src/app/core/services/misiones/misiones.service';
import { UsuarioService } from 'src/app/core/services/usuario/usuario.service';
import { GalleryModalComponent } from 'src/app/shared/components/gallery-modal/gallery-modal.component';
import { ModelComentarioComponent } from 'src/app/shared/components/model-comentario/model-comentario.component';
import { GalleryImage } from 'src/app/shared/models/galeryImage';
import { environment } from 'src/environments/environment';
import { ModalObservacionesComponent } from '../modal-observaciones/modal-observaciones.component';
import { ModalInformacionAuxiliarComponent } from 'src/app/modules/shared/components/modal-informacion-auxiliar/modal-informacion-auxiliar.component';

@Component({
  selector: 'app-table-results',
  templateUrl: './table-results.component.html',
  styleUrls: ['./table-results.component.scss']
})
export class TableResultsComponent implements OnInit {
  @Input() equipo;
  @Input() isRole;
  @Input() actividad;
  @Input() infoIgnored;
  @Input() finished;
  @Input() infoAuxParam;
  mantenimiento = '¿Desea crear un aviso de mantenimiento para esta actividad?';
  id: any;
  token: any;
  rol = [];
  verAvisos = false;
  posicionAux: number;
  gallery: GalleryImage[] = [];
  arrayDelete = [];
  auxiliar = [];
  auxiliarAvisos = [];
  preguntasContains = [];
  infoAux: any
  public infoGuardada: boolean = false;
  idInfoGuardada: any

  constructor(public dialog: MatDialog, private service: Service, private apiservice: ApiServerService, private rutaActiva: ActivatedRoute, private usuarioServices: UsuarioService) {
    this.id = this.rutaActiva.snapshot.params.id;
    this.token = this.usuarioServices.getToken();
    this.rol = this.usuarioServices.getRoles();

  }

  ngOnInit(): void {
    /* this.getListadoInfo() */
    this.cargar()

  }
  cargar() {
    if (this.actividad) {
      this.actividad.preguntas.forEach(element => {
        if (element.Instruction_Id.startsWith("AvisoMtto")) {
          this.avisos(element, this.actividad.id)
        } else {
          for (var key in element.Status) {
            let comentarios = this.buscarComentario(this.actividad.comentarios, element)
            let data = this.buscar(element.Instruction_Id, element.Instruction_Dsc, element.Mission_Id);
            element.preguntasInfo = [];
            element.Status[key].comentarios = comentarios;
            element.comentariosExiste = (comentarios > 0)
            element.Status[key].fotos = data.fotos;
            element.Status[key].detalles = data.detalles;
            element.Status[key].observaciones = data.observaciones;
            //console.log("element.status[key.instruction_seq", element.Status[key].instruction_seq)
            element.preguntasInfo.push(element.Status[key])
            element.nro = this.actividad.id;
            let id
            if (element.id) {
              id = element.id + "-" + element.Mission_Id + "-" + element.Activity_Id + "-" + element.Protocol_Id + "-" + element.Instruction_Id + "-" + element.Instruction_Id;
            } else {
              id = element.Mission_Id + "-" + element.Activity_Id + "-" + element.Protocol_Id + "-" + element.Instruction_Id + "-" + element.Instruction_Id;
            }
            element.idCode = id
            if (this.infoAuxParam) {
              for (let i = 0; i < this.infoAuxParam.length; i++) {
                const itemInfo = this.infoAuxParam[i]
                if (itemInfo.code == id) {
                  element.tieneInfoAux = true
                  break;
                } else {
                  element.tieneInfoAux = false
                }
              }
            }

            if (!element.Instruction_Id.startsWith("Det") && !this.preguntasContains.includes(element.Status[key].Text)) {
              this.auxiliar.unshift(element)
              this.preguntasContains.push(element.Status[key].Text)
            }

          }
        }
      });
      this.auxiliar = this.ordenarInstructions(this.auxiliar)

    }

  }


  getListadoInfo() {
    this.apiservice.getAuxInformationsByOT(this.id).subscribe(
      res => {
        this.infoAux = res.auxInformationDTOs
        /* console.log("res.auxInformationDTOs", res.auxInformationDTOs) */
        this.cargar()

      },
      error => {
        console.log("error", error)

      }
    )

  }


  buscarDetAvisoMtto(result: any, id) {
    let indexDet = 0;
    let detalles = {
      detalle: null,
      persona: "",
      fecha: ""
    }
    for (var key in result.Status) {
      if (key == "AvisoMttoOtros") {
        let filter = this.actividad.mensajes.filter(d => d.Evidence_Type == "" && d.Instruction_Id == 'DetAvisoMttoOtros')
        let index = filter.length - 1;
        detalles.detalle = filter[index].Status.DetAvisoMttoOtros.Observed;
        detalles.persona = filter[index].Who[0].username;
        detalles.fecha = filter[index].When;
        indexDet++;
      }
      else
        if (key.startsWith("Det" + id)) {
          detalles.detalle = result.Status[key].Observed;
          detalles.persona = result.Who[0].username;
          detalles.fecha = result.When;
          indexDet++;
        }
    }

    return detalles;
  }
  avisos(result: any, nro: any) {
  
    for (var key in result.Status) {
      if (key.startsWith("AvisoMtto") && result.Status[key].Observed !== 'N/A') {
        let comentarios = this.buscarComentario(this.actividad.comentarios, result);
        let obj = {
          pregunta: result.Instruction_Dsc,
          aviso: result.Status[key].Observed,
          detalles: this.buscarDetAvisoMtto(result, key),
          Mission_Id: result.Mission_Id,
          Activity_Id: result.Activity_Id,
          Protocol_Id: result.Protocol_Id,
          Instruction_Id: result.Instruction_Id,
          nro: nro,
          id: key,
          _id: result._id,
          observaciones: this.buscarObservacionAvisoMtto(result.Instruction_Id, result.Instruction_Dsc, result.Mission_Id),
          comentarios: comentarios,
          comentariosExiste: (comentarios > 0),
          tieneInfoAux: false,
          idCode: ''

        }
   
        let id = obj.id + "-" + obj.Mission_Id + "-" + obj.Activity_Id + "-" + obj.Protocol_Id + "-" + obj.Instruction_Id + "-" + obj.Instruction_Id;
        obj.idCode = id
        if (this.infoAuxParam) {
          for (let i = 0; i < this.infoAuxParam.length; i++) {
            const itemInfo = this.infoAuxParam[i];
            if (itemInfo.code == id) {
              obj.tieneInfoAux = true
              break;
            } else {
              obj.tieneInfoAux = false
            }
          }
        }

        this.auxiliarAvisos.push(obj)

      }
    }

  }
  buscarObservacionAvisoMtto(instruction_id, instruction_Dsc, mission_id) {
    let observaciones: any = {}
    this.actividad.observaciones.filter(a => a.Mission_Id == mission_id).forEach(m => {
      if ((Array.isArray(m.SubjectPart) && m.SubjectPart.includes(instruction_id) || m.Instruction_Id == instruction_id)) {
        let observacion = (m.Status.Observacion)
        observaciones.lista = (!observaciones.lista) ? observaciones.lista = [] : observaciones.lista;
        observaciones.lista.push(observacion)
        observaciones.id = m.Mission_Id;
      }
    })
    return observaciones;
  }


  ordenarInstructions(array: any) {
    //Instruction_Seq
    return array.sort(function (x, y) {
      if (new Number(x.Instruction_Seq) < new Number(y.Instruction_Seq)) {
        return -1;
      }

      if (new Number(x.Instruction_Seq) > new Number(y.Instruction_Seq)) {
        return 1;
      }

      return 0;
    });
  }
  buscarComentario(lista: any, item: any): any {
    let id = item.Mission_Id + "-" + item.Activity_Id + "-" + item.Protocol_Id + "-" + item.Instruction_Id;
    let e = lista.filter(d => d.code == id);
    return e.length;

  }


  buscar(instruction_id, instruction_Dsc, mission_id) {
    let fotos = null;
    let observaciones: any = {}
    let detalles = null
    this.actividad.mensajes.filter(a => a.Mission_Id == mission_id).forEach(m => {
      if (Array.isArray(m.SubjectPart) && m.SubjectPart.includes(instruction_id)) {
        if (Array.isArray(m.SubjectPart) && m.SubjectPart.includes(instruction_id) && m.Status[m.Instruction_Id] && m.Status[m.Instruction_Id].Observed == "Recolectada") {
          fotos = (m.Status[m.Instruction_Id])
          fotos.id = m.Mission_Id;
        }
        if (Array.isArray(m.SubjectPart) && m.SubjectPart.includes(instruction_id) && m.Instruction_Id.startsWith("Detalle") && m.Status[m.Instruction_Id] && m.Status[m.Instruction_Id].Observed != "") {
          detalles = {}
          detalles.detalle = (m.Status[m.Instruction_Id].Observed);
          detalles.persona = m.Who[0].username;
          detalles.fecha = m.When;
        }

      }

    })

    this.actividad.observaciones.filter(a => a.Mission_Id == mission_id).forEach(m => {
      if ((Array.isArray(m.SubjectPart) && m.SubjectPart.includes(instruction_id) || m.Instruction_Id == instruction_id) && m.Evidence_Type == "AvisoDeMantenimiento") {
        let observacion = (m.Status.Observacion)
        observaciones.lista = (!observaciones.lista) ? observaciones.lista = [] : observaciones.lista;
        observaciones.lista.push(observacion)
        observaciones.id = m.Mission_Id;
      }
    })
    return {
      fotos: fotos,
      detalles: detalles,
      observaciones: observaciones
    }
  }

  showFoto(data, titulo) {
    this.gallery = [];
    let fotos = data.Evidence;
    let descripcion = data.Observed;
    var i = 0;
    fotos.forEach(f => {
      const image = new GalleryImage();
      image.alt = f;
      image.src = `${environment.apiUrl}/api/instance/instancia-de-prueba/mission/Mission_${data.id}/file/${f}?token=${this.token}`;
      image.position = (this.posicionAux) ? this.posicionAux : i;
      this.gallery.push(image);
      i = i + 1;
    })
    this.dialog.open(GalleryModalComponent, { panelClass: 'custom-dialog-container', data: { titulo: titulo, descripcion: descripcion, gallery: this.gallery, position: 0 } });
  }

  showObservaciones(data, titulo) {
    let info = []
    data.lista.forEach(item => {
      let observacion: any = {}
      observacion.gallery = [];
      let fotos = item.Evidence;
      observacion.descripcion = item.Observed;
      var i = 0;
      fotos.forEach(f => {
        const image = new GalleryImage();
        image.alt = f;
        image.src = `${environment.apiUrl}/api/instance/instancia-de-prueba/mission/Mission_${data.id}/file/${f}?token=${this.token}`;
        image.position = (this.posicionAux) ? this.posicionAux : i;
        observacion.gallery.push(image);
        i = i + 1;
      })
      info.push(observacion)

    })

    this.dialog.open(ModalObservacionesComponent, { panelClass: 'custom-dialog-container', data: { info: info } });
  }
  showDetalles(data, titulo) {
    if (data) {
      this.dialog.open(ModelComentarioComponent, { panelClass: 'custom-dialog-container', data: { detalle: data, type: "detalle" } });
    }

  }
  verComentario(nro, item, comentarios) {
    let id = item.Mission_Id + "-" + item.Activity_Id + "-" + item.Protocol_Id + "-" + item.Instruction_Id;
    this.dialog.open(ModelComentarioComponent, { panelClass: 'custom-dialog-container', data: { nro: nro, _id: id, comentarios: comentarios, type: "ver" } });

  }

  newComentario(nro, item, comentarios) {
    let id = item.Mission_Id + "-" + item.Activity_Id + "-" + item.Protocol_Id + "-" + item.Instruction_Id;
    const dialogRef = this.dialog.open(ModelComentarioComponent, { panelClass: 'custom-dialog-container', data: { nro: nro, _id: id, comentarios: comentarios, type: "crear" } });
    dialogRef.afterClosed().subscribe(() => {
      let element = this.actividad.preguntas.find(d => d._id == item._id);
      this.apiservice.getComentario(id).subscribe(res => {
        element.comentariosExiste = (res.commentsDTOs.length > 0);

      })

    });

  }


  newAviso(nro, item, comentarios) {
    let id = item.Mission_Id + "-" + item.Activity_Id + "-" + item.Protocol_Id + "-" + item.Instruction_Id;
    const dialogRef = this.dialog.open(ModelComentarioComponent, { panelClass: 'custom-dialog-container', data: { nro: nro, _id: id, comentarios: comentarios, type: "crear" } });
    dialogRef.afterClosed().subscribe(() => {
      let element = this.actividad.preguntas.find(d => d._id == item._id);
      for (var key in element.Status) {
        this.apiservice.getComentario(id).subscribe(res => {
          element.Status[key].comentarios = res.commentsDTOs.length;
        })
      }
    });

  }

  newInformacionAux(item) {
    let id
    if (item.id) {
      id = item.id + "-" + item.Mission_Id + "-" + item.Activity_Id + "-" + item.Protocol_Id + "-" + item.Instruction_Id + "-" + item.Instruction_Id;
    } else {
      id = item.Mission_Id + "-" + item.Activity_Id + "-" + item.Protocol_Id + "-" + item.Instruction_Id + "-" + item.Instruction_Id;

    }
    let nro_ot = item.nro

  
    const dialogRef = this.dialog.open(ModalInformacionAuxiliarComponent, { panelClass: 'custom-dialog-container', data: { isRole:this.isRole,id, nro_ot, finished: this.finished, equipo: this.equipo, instruction_Seq: item.Instruction_Seq } });
    dialogRef.afterClosed().subscribe((guardado: boolean) => {
      if (guardado) {
        let aux = this.auxiliar.find(d => d.idCode == id);
        let aviso = this.auxiliarAvisos.find(d => d.idCode == id);
        if (aux) aux.tieneInfoAux = true;
        if (aviso) aviso.tieneInfoAux = true;

      }
    });

  }

}
