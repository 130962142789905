import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from './modules/home/components/page-not-found/page-not-found.component';
import {AuthGuard} from './core/guards/auth.guard';
import {ErrorComponent} from './modules/home/components/error/error.component';


const routes: Routes = [
  {path: 'error', component: ErrorComponent, canActivate: [AuthGuard], canLoad: [AuthGuard]},
  {path: 'notFound', component: PageNotFoundComponent, canActivate: [AuthGuard], canLoad: [AuthGuard]},
  {path: '**', component: PageNotFoundComponent, canActivate: [AuthGuard], canLoad: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: false})], // <-- debugging purposes only
  exports: [RouterModule]
})
export class AppRoutingModule {
}
