import { CommonModule } from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { AngularMaterialModule } from 'src/app/shared/angular-material/angular-material.module';
import { AdministracionDePuntajesComponent } from './pages/administracion-de-puntajes/administracion-de-puntajes.component';
import { SharedModule } from 'src/app/shared/components/shared.module';
import { PreguntasComponent } from './components/preguntas/preguntas.component';
import { DetalleComponent } from './pages/detalle/detalle.component';
import { TableResultsComponent } from './components/table-results/table-results.component';
import { ModalObservacionesComponent } from './components/modal-observaciones/modal-observaciones.component';
import { AvisosMantenimientoComponent } from './components/avisos-mantenimiento/avisos-mantenimiento.component';
import { ModalAvisosComponent } from './components/modal-avisos/modal-avisos.component';



@NgModule({
  declarations:[ AdministracionDePuntajesComponent, PreguntasComponent, DetalleComponent, TableResultsComponent, ModalObservacionesComponent, AvisosMantenimientoComponent, ModalAvisosComponent],
  imports:[ CommonModule, RouterModule,AngularMaterialModule,SharedModule],
  exports:[TableResultsComponent]
})
export class AdministracionModule { }
