


<div class="ui container">
    <app-header-component [score]='score'  [title]='descripcion' [estado]='state'
    [groupBtn1]="true" [groupBtn2]="descripcion=='Evaluación completa'" (accionInformacionAux)="openInformacionAux($event)" (accionModal)="openAvisos($event)">
    </app-header-component>
    <div id="DATA-PDF">
        <div style='overflow-x: auto;' style="margin-bottom: 2em;" *ngIf="numero_ot">
            <table class="ui celled structured table unstackable">
                <thead>
                    <tr>
                        <th colspan="2" class="center aligned">DATOS DE CABECERA DE LA EVALUACIÓN TÉCNICA</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class='label '>N° OT:<span class='negrita'>{{numero_ot}}</span></td>
                        <td class='label'> Fecha:<span class='negrita'>{{fecha|
                            date:'dd/MM/yyyy'}}</span></td>
                    </tr>
                    <tr>
                        <td class='label'>Planta:<span class='negrita'>{{planta}}</span></td>
                        <td class='label'>Evaluó:<span class='negrita'>{{audito}}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="ui divider no-pdf-flex"></div>

        <div *ngFor="let p of protocolos;index as i" class="container-protocol no-pdf">
            <h3 class='title-style ' *ngIf="descripcion=='Evaluación completa'">{{p.protocolo}}</h3>
            <div class="ui grid " style="margin-top: 0.5em;" *ngIf="protocolos.length>0">
                <div class="sixteen wide column">
                    <div class="ui styled accordion " style="margin-top: 0.5em;"
                        *ngFor="let item of p.datos.equipos;index as i">
                        <div [id]="'panel'+p.protocolo+item.equipo+i" class="title " (click)="activePanel(p.protocolo+item.equipo+i)">
                            <i class="dropdown icon"></i>
                            
                            <span *ngIf="item.equipo"> Equipo {{item.equipo}}</span>
                            - {{item.descripcion}} 
                            <!--<span *ngIf="!item.equipo && item.ut"> UT {{item.ut}}</span>-->
                            <span *ngIf="item.infoIgnored">- MISION
                                IGNORADA</span>
                            <span class="puntaje" *ngIf=" !item.infoIgnored">
                                Puntaje: 
                                <span *ngIf="item.puntaje!=null" class="negrita">{{item.puntaje}}</span>
                                <span *ngIf="item.puntaje==null" class="negrita"><i class="clock outline icon"></i></span>
                            </span>

                        </div>
                        <div [id]="'content'+p.protocolo+item.equipo+i" class="content ">
                            <app-table-results *ngFor="let actividad of item.actividades" [actividad]="actividad"
                                [equipo]="item.equipo" [isRole]="isRole" [finished]="finished" [infoAuxParam]="infoAux">
                            </app-table-results>
                            <app-table-results [infoIgnored]="item.infoIgnored" ></app-table-results>
                        </div>


                    </div>
                </div>
            </div>
        </div>
        <div *ngFor="let p of protocolos;index as i" class="container-protocol si-pdf">
            <h3 class='title-style ' *ngIf="descripcion=='Evaluación completa'">{{p.protocolo}}</h3>
            <div class="" style="margin-top: 0.5em;" *ngFor="let item of p.datos.equipos;index as i">
                <i class="dropdown icon"></i>
                {{item.descripcion}} - 
                <span *ngIf="item.equipo"> Equipo {{item.equipo}}</span>
                <span *ngIf="!item.equipo && item.ut"> UT {{item.ut}}</span> <span *ngIf="item.infoIgnored">- MISION
                    IGNORADA</span>
                <span class="puntaje" *ngIf=" !item.infoIgnored">
                    <span *ngIf="item.puntaje!=null" class="negrita">{{item.puntaje}}</span>
                    <span *ngIf="item.puntaje==null" class="negrita"><i class="clock outline icon"></i></span>
                    Puntaje:

                </span>
                <app-table-results *ngFor="let actividad of item.actividades" [actividad]="actividad"
                    [equipo]="item.equipo" [infoAuxParam]="infoAux">
                </app-table-results>
                <app-table-results [infoIgnored]="item.infoIgnored"></app-table-results>

            </div>
            <div class="ui divider "></div>

        </div>
    </div>



    <div class="ui grid">
        <div class="extra content">
            <button style="margin-top: 3em;" class="ui right labeled icon button button-filter primary"
                (click)="goHome()">
                <i class="home
                          icon"></i>
                Volver
            </button>
        </div>
    </div>

</div>