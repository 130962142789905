<div class="ui pagination menu">
    <a class="icon item" (click)="first()">
      <i class="angle double left icon"></i>
    </a>
    <a class="icon item" (click)="previus()">
      <i class="angle left icon"></i>
    </a>
  
    <ng-container *ngFor="let page of pagesTotals">
      <ng-container *ngIf="page !== 0">
        <a class="item" [ngClass]="{'pagina-actual': page === numberPage}" (click)="cambiarPagina(page)">
          <p>{{ page }}</p>
        </a>
      </ng-container>
    </ng-container>
    <a class="item" *ngIf="pagesTotal > pagesToShow &&  numberPage <= (pagesTotal - pagesToShow )">
      <p>...</p>
    </a>
  
    <a class="icon item" (click)="next()">
      <i class="angle right icon"></i>
    </a>
    <a class="icon item" (click)="last()">
      <i class="angle double right icon"></i>
    </a>
  </div>
  