import { EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Component, OnInit } from '@angular/core';

interface PaginatorChange {
  pageNumber: number;
}

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
  @Input() total = 0;
  @Input() pagesTotal: number;
  @Input() pagesTotals: number[] = [];
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
  pagesToShow: number = 5;

  numberPage = 1;
  sizePage = 11;
  pages: number[] = [];

  constructor() { }


  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.pagesToShow = (this.pagesTotal < this.pagesToShow ) ? this.pagesTotal :  this.pagesToShow;
    this.pagesTotals = Array.from({ length: this.pagesTotal }, (_, i) => i + 1);
    this.move(1);
  }

  move(pageNumber: number) {
    const start = Math.max(1, pageNumber - 1);
    const end = Math.min(start + this.pagesToShow - 1, this.pagesTotal);
    this.pagesTotals = Array.from({ length: end - start + 1 }, (_, i) => start + i);
   
    if (this.pagesTotals.length < this.pagesToShow ) {
      let diff = (this.pagesToShow - this.pagesTotals.length );
      let min = Math.min.apply(null, this.pagesTotals);
      for (let i = 0; i < diff; i++) {
         this.pagesTotals.unshift(min-(i+1))
      }
    }


  }

  previus() {
    if (this.numberPage > 1) {
      this.numberPage = this.numberPage - 1;
      this.pageChange.emit(this.numberPage);
      this.move(this.numberPage);
    }
  }

  next() {
    if (this.numberPage < this.pagesTotal) {
      this.numberPage = this.numberPage + 1;
      this.pageChange.emit(this.numberPage);
      this.move(this.numberPage);
    }
  }

  cambiarPagina(newPageNumber: number) {
    this.numberPage = newPageNumber;
    this.pageChange.emit(this.numberPage);
    this.move(this.numberPage);
  }

  first() {
    this.numberPage = 1;
    this.pageChange.emit(this.numberPage);
    this.move(this.numberPage);
  }

  last() {
    this.numberPage = this.pagesTotal;
    this.pageChange.emit(this.numberPage);
    this.move(this.pagesTotal - 3);
  }
}
