<div *ngIf="auxiliarAvisos.length> 0">
  <div class="ui">
    <h3 class="header">Resumen de avisos</h3>
    <h4></h4>
    <ng-container *ngFor="let protocolo of protocoloTabla | keyvalue">
      <h3 class="title-style">{{ protocolo.key }}</h3>
      <div *ngFor="let equipo of protocolo.value | keyvalue" style="margin-bottom: 1em;">

        <div *ngIf="equipo.value.length >0">

          <h4>Equipo {{ equipo.key }} </h4>
          <table class="ui single line table">
            <thead>
              <tr>
                <th class="center aligned">Actividad</th>
                <th class="center aligned">Tipo de Aviso</th>
                <th class="center aligned">Descripción</th>
                <th class="center aligned">Observaciones</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let elemento of equipo.value">
                <ng-container >
  
                  <td style="max-width: 200px !important;white-space: pre-wrap;" class="center aligned">{{
                    elemento.actividad }}</td>
                  <td style="max-width: 200px !important;white-space: pre-wrap;" class="center aligned">{{ elemento.tipo
                    }}</td>
                  <td style="max-width: 200px !important;white-space: pre-wrap;" class="center aligned">{{
                    elemento.descripcion.detalle }}</td>
                  <td style="max-width: 200px !important;white-space: pre-wrap;" class="center aligned">
                    <i *ngIf="elemento.observaciones.lista" class="search icon pointer font-blue"
                      (click)="showObservaciones(elemento.observaciones, elemento.Text)"></i>
                  </td>
                </ng-container>
  
              </tr>
            </tbody>
          </table>
        </div>
        
      </div>
    </ng-container>
  </div>
</div>


<div *ngIf="auxiliarAvisos.length == 0">
  <div class="ui">
    <h3 class="header">Resumen de avisos</h3>
    <div class="ui warning message">
      <i class="close icon"></i>
      <div class="header">
        No se encontraron avisos
      </div>
    </div>
  </div>
</div>