<div class="error-404-container">
  <mat-card class="error-404-card">

    <mat-card-header>
      <img id='logo' mat-card-avatar src="./assets/img/logo-tgn.png" alt="Logo">
      <mat-card-title>Error 404</mat-card-title>
      <mat-card-subtitle>No encontramos la p&aacute;gina buscada</mat-card-subtitle>
    </mat-card-header>

    <mat-card-actions class="error-404-card-actions">
      <button class="ui  button primary" color="primary" appGoBack>VOLVER</button>
    </mat-card-actions>

  </mat-card>
</div>
