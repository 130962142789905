import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServerService } from 'src/app/core/services/api/api-server.service';
import { Service } from 'src/app/core/services/misiones/misiones.service';
import { MatDialog } from '@angular/material/dialog';
import { UsuarioService } from 'src/app/core/services/usuario/usuario.service';
import { FiltroActividades } from 'src/app/shared/models/filtroActividades';
import { ModalAvisosComponent } from '../../components/modal-avisos/modal-avisos.component';
import { ModalDescargarGuardarInformacionAuxiliarComponent } from 'src/app/modules/shared/components/modal-descargar-guardar-informacion-auxiliar/modal-descargar-guardar-informacion-auxiliar.component';
declare var $: any;

@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.scss']
})
export class DetalleComponent implements OnInit {
  numero_ot: any;
  fecha: any;
  planta: any;
  audito: any;
  puntaje: any;
  descripcion: string;
  id: string;
  state: string;
  missionsIgnored = []
  protocolos: any[] = []
  mensajes: any[] = []
  punajes: any[] = []
  score = undefined;
  mapScoreMisions: any = []
  code: any
  finished: boolean
  infoAux: any
  isRole = false;
  constructor(
    public dialog: MatDialog,
    private servicesUsuario: UsuarioService,
    private router: Router,
    private usuarioService: UsuarioService,
    private rutaActiva: ActivatedRoute,
    private service: Service,
    private serviceApi: ApiServerService,
  ) {


    $("#spinner-load").css("display", "block");
    let filterProtocol = this.rutaActiva.snapshot.params.descripcion;
    this.descripcion = filterProtocol.replace(/_/g, ' ');
    this.id = this.rutaActiva.snapshot.params.id;
    this.state = this.rutaActiva.snapshot.params.state;
    let filtros = new FiltroActividades()
    filtros.numero = this.id;
    filtros.descripcion = filterProtocol;
    let plantas = this.servicesUsuario.getPlantas();
    this.role()
    this.stateInformacionAux();
    this.serviceApi.getAll(1, filtros, 1, plantas).subscribe(results => {
      let dataP = results.data[0];
      this.score = (dataP) ? dataP.score : undefined;
      this.fecha = (dataP) ? dataP.When : undefined;
      this.numero_ot = (dataP) ? dataP.Status.OT.Observed : undefined;
      this.planta = (dataP) ? dataP.Status.UT.Observed : undefined;
      this.audito = (dataP) ? dataP.Who[0].username : undefined;
      let plantas = this.usuarioService.getPlantas();
      this.mapScoreMisions = results.mapScoreMisions;
      if (plantas && !plantas.includes('*') && !plantas.includes(this.planta)) {
        this.router.navigate(['/home'])
      }
      this.service.getAllNumeroOT(this.descripcion, this.id).subscribe(res => {
        let misiones = []

        res.data.forEach(element => {
          misiones.push('"' + element.Mission_Id + '"')
        });
        this.service.getDetalle(this.descripcion, misiones).subscribe(data => {
          this.mensajes = data.data;
          this.serviceApi.getComentarios(this.id).subscribe(c => {
            if (c.commentsDTOs && c.commentsDTOs[0]) {
              this.code = c.commentsDTOs[0].code
            }
            var protocols = this.groupByPrococol(data.data.reverse());
            for (var keyP in protocols) {

              let equiposDataFilter = this.groupByEquipo(protocols[keyP].equiposData);
              protocols[keyP].equipos = []
              for (var keyE in equiposDataFilter) {

                protocols[keyP].equipos.push(equiposDataFilter[keyE])
                if (equiposDataFilter[keyE].preguntas) {
                  let actividades = (this.groupByActividad(equiposDataFilter[keyE].preguntas));
                  let comentarios = c.commentsDTOs;
                  for (var key in actividades) {

                    let filterActividades = actividades[key];
                    let pregunta = filterActividades.find(a => a.Instruction_Id == "PreguntaAdicional" && a.Activity_Dsc == key)
                    let respuesta = filterActividades.find(a => a.Instruction_Id == "RespuestaAdicional" && a.Activity_Dsc == key)
                    let preguntaAdicional = this.preguntasAdicionales(pregunta, respuesta)
                    if (preguntaAdicional) {
                      actividades[key].push(preguntaAdicional)
                    }
                    let mensajesByMission = this.mensajes.filter(d => d.Mission_Id == equiposDataFilter[keyE].id)
                    let preguntas = actividades[key].filter(a => (a.Mission_Id == keyE) && a.Instruction_Id !== "RespuestaAdicional" && a.Instruction_Id !== "PreguntaAdicional")
                    equiposDataFilter[keyE].actividades.unshift({ actividad: key, mensajes: mensajesByMission.reverse(), observaciones: this.filtrarObservaciones(this.mensajes), preguntas: this.filtrarUltimasPreguntas(preguntas), id: this.id, comentarios: comentarios })
                  }
                  equiposDataFilter[keyE].actividades = this.reordenarActividades(equiposDataFilter[keyE].actividades)
                }
              }
              let protocolo = keyP.replace(/_/g, " ");
              let score = { score: 0 };
              let { equiposData, ...datos } = protocols[keyP];
              let protocol = { protocolo, score: (!score) ? "-" : score.score, datos };
              this.protocolos.push(protocol)

            }
            this.protocolos = this.protocolos.sort((a, b) => a.protocolo.localeCompare(b.protocolo));

            this.protocolos.forEach(p => {
              p.datos.equipos = p.datos.equipos.sort((a, b) => a.equipo.localeCompare(b.equipo));
            })
            $('#spinner-load').css("display", "none");
          })
        })
      })

    })

  }
  eliminarDiacriticos(texto) {
    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
  }
  reordenarActividades(array: any) {
    let orden = ["operacion", "orden y limpieza", "instrumentacion", "Seguridad", "Otros", "AC Mecánico"]
    var newArray = []
    orden.forEach((elem1: any) => {
      array.forEach((elem2) => {
        if (this.eliminarDiacriticos(elem2.actividad) == this.eliminarDiacriticos(elem1)) {
          newArray.push(elem2);
        }
      });
    });
    return newArray;
  }
  filtrarUltimasPreguntas(preguntas: any) {
    let preguntasFilter = []
    preguntas.forEach(e => {
      if (!preguntasFilter.find(d => d.Instruction_Dsc == e.Instruction_Dsc)) {
        preguntasFilter.push(e)
      }
    });
    return preguntasFilter;
  }
  filtrarObservaciones(preguntas: any) {
    return preguntas.filter(d => d.Evidence_Type == "AvisoDeMantenimiento")
  }
  ordenarPreguntas(arr: any) {
    return arr.sort(function (x, y) {
      if (x.Instruction_Seq < y.Instruction_Seq) {
        return -1;
      }

      if (x.Instruction_Seq > y.Instruction_Seq) {
        return 1;
      }

      return 0;
    });
  }
  preguntasAdicionales(pregunta: any, respuesta: any) {

    let p;
    let r;
    if (pregunta && respuesta) {
      p = (pregunta.Status.PreguntaAdicional.Observed)
      for (let keyr in respuesta.Status) {
        r = (respuesta.Status[keyr].Observed)
      }
      let data = {
        Activity_Dsc: pregunta.Activity_Dsc,
        Activity_Id: pregunta.Activity_Id,
        Mission_Id: pregunta.Mission_Id,
        Protocol_Id: pregunta.Protocol_Id,
        Status: {
          PreguntaAdicional: {
            Text: p,
            Observed: r
          }
        },
        Instruction_Id: "PreguntaRespuestaAdicional",
        _id: pregunta._id
      }
      if (p != '') {
        return data
      }

    }


  }

  ngOnInit(): void {
    this.getListadoInfo()

  }
  ngAfterViewInit(): void {
    $('.ui.accordion').accordion();
  }
  goHome() {
    this.router.navigate(['/']);
  }
  groupByMission(data) {
    let key = "Mission_Id";
    return data.reduce((acc, cur) => {
      acc[cur[key]] = acc[cur[key]] || [];
      if (!acc[cur[key]].equiposData) {
        acc[cur[key]].equiposData = []
      }
      acc[cur[key]].equiposData.push(cur); return acc;

    }, [])
  }
  groupByPrococol(data) {
    let key = "Protocol_Id";
    return data.reduce((acc, cur) => {
      acc[cur[key]] = acc[cur[key]] || [];
      if (!acc[cur[key]].equiposData) {
        acc[cur[key]].equiposData = []
      }
      acc[cur[key]].equiposData.push(cur); return acc;
    }, [])
  }
  groupByEquipo(data) {
    let key = "Mission_Id";
    return data.reduce((acc, cur) => {
      acc[cur[key]] = acc[cur[key]] || [];
      acc[cur[key]].puntaje = undefined;
      acc[cur[key]].id = cur.Mission_Id;
      if (cur.Activity_Dsc == "Mission Ignored") {
        acc[cur[key]].infoIgnored = cur.Status;
        this.missionsIgnored.push(cur.Mission_Id)
      }

      if (cur.Instruction_Id == "CargaInicial") {
        acc[cur[key]].descripcion = (cur.Status) ? cur.Status.Description.Observed : "";
        acc[cur[key]].equipo = (cur.Status && cur.Status.SNEquipo) ? cur.Status.SNEquipo.Observed : "";
        acc[cur[key]].ut = (cur.Status && cur.Status.UT) ? cur.Status.UT.Observed : "";

        //this.serviceApi.getScoreMissions(cur.Mission_Id, cur.Protocol_Id).subscribe(res => {

        if (this.mapScoreMisions && this.mapScoreMisions[cur.Mission_Id] != null) {

          acc[cur[key]].puntaje = Math.round(this.mapScoreMisions[cur.Mission_Id]);
        }
        //})

      } else if (cur.Evidence_Type == "cuestionario" || cur.Instruction_Id == "RespuestaAdicional" || cur.Instruction_Id == "PreguntaAdicional") {
        if (!acc[cur[key]].preguntas) {
          acc[cur[key]].preguntas = []
          acc[cur[key]].actividades = []
        }
        acc[cur[key]].preguntas.push(cur);
      }

      return acc;
    }, [])
  }

  groupByActividad(data) {
    let key = "Activity_Dsc";
    return data.reduce((acc, cur) => {
      acc[cur[key]] = acc[cur[key]] || [];
      acc[cur[key]].push(cur);
      return acc;
    }, [])
  }


  activePanel(i) {
    if (document.getElementById("panel" + i).classList.contains("active")) {
      document.getElementById("panel" + i).classList.remove("active");
      document.getElementById("content" + i).classList.remove("active");
    } else {
      document.getElementById("panel" + i).classList.add("active");
      document.getElementById("content" + i).classList.add("active");
    }

  }

  openAvisos(event: any) {
    const dialogRef = this.dialog.open(ModalAvisosComponent, {
      panelClass: 'custom-dialog-container',
      data: { protocolos: this.protocolos },
      width: '80%' // Establece el ancho al 80% del viewport
    });

  }
  getListadoInfo() {
    this.serviceApi.getAuxInformationsByOT(this.id).subscribe(
      res => {
        this.infoAux = res.auxInformationDTOs

      },
      error => {
        console.log("error", error)

      }
    )

  }
  openInformacionAux(item: any) {
    console.log(this.protocolos)
    //let id = item.Mission_Id + "-" + item.Activity_Id + "-" + item.Protocol_Id + "-" + item.Instruction_Id + "-" + item.Instruction_Id;
    let nro_ot = this.id
    const dialogRef = this.dialog.open(ModalDescargarGuardarInformacionAuxiliarComponent, { panelClass: 'custom-dialog-container', data: { isRole: this.isRole, planta: this.planta, id: this.code, nro_ot, protocolo: this.descripcion, protocolos: this.protocolos, fecha: this.fecha } });
    dialogRef.afterClosed().subscribe((finished: boolean) => {
      if (finished) {
        this.finished = true;
      }
    });

  }

  stateInformacionAux() {
    this.serviceApi.getAuxInformationsPDF(this.id).subscribe(
      res => {
        if (res && res.pdfAuxInformationDTOList && res.pdfAuxInformationDTOList.length > 0) {
          this.finished = res.pdfAuxInformationDTOList[0].finished
        }

      },
      error => {
        console.log("error", error)

      }

    )
  }

  role() {
    let options = ['Jefe de Análisis de Condición', 'Evaluador Tec.Planta'];
    this.isRole = this.usuarioService.isRoles(options);
  }
}
