import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";


@Injectable({
    providedIn: 'root'
})
export class ApiServerService {

    constructor(private http: HttpClient) {
    }
    pruebanUXI() {
        let data = {
            "codigoCiaAseguradora": 0,
            "codigoInfoAuto": "",
            "fechaReferencia": "2023-06-21T15:21:57.538Z",
            "itemId": 0,
            "productorId": 0
        }
        return this.http.post(`http://3.90.170.235/nuxi-back/api/Combos/RecuperaComboUnidadNegocioMeridional?login=admin&password=admin`, data).pipe(
            map((isAlive: any) => {
                return isAlive;
            })
        );
    }
    getAll(page, filtros, max, plantas) {
        max = (max) ? max : 11;
        for (const key in filtros) {
            if (filtros.hasOwnProperty(key) && filtros[key] === "") {
                filtros[key] = null;
            }
        }
        filtros.plantas = plantas;
        return this.http.post<any>(`${environment.urlBack}/allResults?pageNumber=${page}&pageSize=${max}`, filtros).pipe(tap(
            (res: any) => {
                return res;
            })
        );
    }
    /*getScoreMissions(missionId: any, protocol: any) {
        let data = {
            missionId: missionId,
            protocol: protocol
        }
        return this.http.post<any>(`${environment.urlBack}/score-mission`, data).pipe(tap(
            (res: any) => {
                return res;
            })
        );
    }*/
    newComentario(data: any) {
        return this.http.post(`${environment.urlBack}/detail/comments`, data).pipe(
            map((isAlive: any) => {
                return isAlive;
            })
        );
    }
    getComentario(id: any) {
        return this.http.get(`${environment.urlBack}/detail/comments/${id}`).pipe(
            map((isAlive: any) => {
                return isAlive;
            })
        );
    }

    getPuntajes() {
        return this.http.get(`${environment.urlBack}/protocols/get-all`).pipe(
            map((isAlive: any) => {
                return isAlive;
            })
        );
    }
    updatePuntajes(data: any) {
        return this.http.post(`${environment.urlBack}/protocols/update`, data).pipe(
            map((isAlive: any) => {
                return isAlive;
            })
        );
    }
    getComentarios(nro: any) {
        return this.http.get(`${environment.urlBack}/detail/comments/ot/${nro}`).pipe(
            map((isAlive: any) => {
                return isAlive;
            })
        );
    }

    getAuxInformations(nro_ot: any) {
        return this.http.get(`${environment.urlBack}/api/auxInformations/${nro_ot}`).pipe(
            map((isAlive: any) => {
                return isAlive;
            })
        );
    }
    getAuxInformationsByOT(nro_ot: any) {
        return this.http.get(`${environment.urlBack}/api/auxInformations/ot/${nro_ot}`).pipe(
            map((isAlive: any) => {
                return isAlive;
            })
        );
    }
    updateAuxInformations(body: any) {
        return this.http.post(`${environment.urlBack}/api/auxInformations`, body).pipe(
            map((isAlive: any) => {
                return isAlive;
            })
        );
    }
    getAuxInformationsPDF(codigo: any) {
        return this.http.get(`${environment.urlBack}/api/auxInformations/pdf/${codigo}`).pipe(
            map((isAlive: any) => {
                return isAlive;
            })
        );
    }
    updateAuxInformationsPDF(body: any) {
        return this.http.post(`${environment.urlBack}/api/auxInformations/pdf`, body).pipe(
            map((isAlive: any) => {
                return isAlive;
            })
        );
    }
    finalizarAuxInfoPDF(body: any) {
        return this.http.post(`${environment.urlBack}/api/auxInformations/finished`, body).pipe(
            map((isAlive: any) => {
                return isAlive;
            })
        );
    }

}
