import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UsuarioService } from '../usuario/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class Service {
  token: string;
  constructor(private http: HttpClient, private usuarioServices: UsuarioService) {
     this.token = this.usuarioServices.getToken();
  }

  getMissionIgnored(nro: any) {
    return this.http.get(`${environment.apiUrl}/api/results?q={"Activity_Dsc": "Mission Ignored"}`).pipe(
        map((isAlive: any) => {
            return isAlive;
        })
    );
}

  getDetalle(descripcion, misiones) {
    var query = '';
    if (descripcion == "Evaluación completa") {
      query = query.concat(`api/results?orderBy={"When":1}&q={"Mission_Id":{"$in":[${misiones}]},"Protocol_Id": {"$in": ["Compresion_de_gas","Generacion_electrica","Valvulas","Separadores","Patines","FF_Gas","FF_Agua","TE_Agua","BB_Agua","SAI","CAI","CCM","UPS","HMI","PLC","EQP","Planta_de_agua"]}
    }`)
    } else {
      descripcion = descripcion.replaceAll(' ', '_')
      query = query.concat(`api/results?orderBy={"When":1}&q={"Protocol_Id":"${descripcion}","Mission_Id":{"$in":[${misiones}]}}`)
    }
    return this.http.get<any>(`${environment.apiUrl}/${query}&token=${this.token}`).pipe(tap(
      (res: any) => {
        return res;
      })
    );

  }
  getAllNumeroOT(descripcion, data) {
    var query = '';
    if (descripcion == "Evaluación completa") {
      query = query.concat(`api/results?orderBy={"When":-1}&q={"Status.OT.Observed":"${data}"}`)
    } else {
      descripcion = descripcion.replaceAll(' ', '_')
      query = query.concat(`api/results?orderBy={"When":-1}&q={"Protocol_Id":"${descripcion}","Status.OT.Observed":"${data}"}`)
    }
    return this.http.get<any>(`${environment.apiUrl}/${query}&token=${this.token}`).pipe(tap(
      (res: any) => {
        return res;
      })
    );

  }

  getByProtocolAndMissionId(protocolo, id) {
    var query = '';
    
    query = query.concat(`api/results?orderBy={"When":-1}&q={"Protocol_Id": "${protocolo}", "Mission_Id": "${id}"}`)
    return this.http.get<any>(`${environment.apiUrl}/${query}&token=${this.token}`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }











}
