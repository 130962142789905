import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GalleryImage } from 'src/app/shared/models/galeryImage';

@Injectable({
  providedIn:'root'
})
export class GaleriaService {
  private galleryImages:GalleryImage[] = [];
  private gallery$:BehaviorSubject<GalleryImage[]> = new BehaviorSubject(this.galleryImages);
  private gallerySelected$:BehaviorSubject<GalleryImage> = new BehaviorSubject(undefined);

  constructor() {
  }

  getGallery():Observable<GalleryImage[]> {
    return this.gallery$.asObservable();
  }

  getImageSelected():Observable<GalleryImage> {
    return this.gallerySelected$.asObservable();
  }

  createGallery(galery:GalleryImage[] ):void {
    this.galleryImages = galery;
    let tope=(this.galleryImages.length) -1;
    this.galleryImages.forEach(img=>{
      img.first=(img.position === 0),
      img.last= (img.position === tope)
    })
   
    this.gallery$.next(this.galleryImages);
  }

  selectImage(position:number):void {
    if (position >= 0 && position < this.galleryImages.length) {
      this.gallerySelected$.next(this.galleryImages[position]);
    }
  }
}
