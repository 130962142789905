export const environment = {

 // test
 /*   production:false,
     apiUrl:'http://tgn.smartworkar.com:8888',
    //apiUrl:'http://test.smartworkar.com:8888',
    urlAssets:'http://tgn-auditoria.candoit.com.ar',
   applicationName:'/',
   urlBack:'https://tgn-auditoria-back.candoit.com.ar',
   user: {
     username:"tgntesting@vreadynow.com",
     password:"larealidadesvirtual"
   }
   */
// qa
//  production:true,
//  apiUrl:'http://tgnrapoc.tgn.com.ar:8888',
//  urlAssets:'http://tgnrapoc.tgn.com.ar:8088/evaluaciones',
//  applicationName:'/',
//  urlBack:'http://tgnrapoc.tgn.com.ar:8088/tgn-auditoria-back',
//  user: {
//    username:"api_client@vreadynow.com",
//   password:"cli3ntPass3470"
// }


 production:true,
 apiUrl:'http://tgnrap.tgn.com.ar:8888',
 urlAssets:'http://tgnrap.tgn.com.ar:8088/evaluaciones',
 applicationName:'/',
 urlBack:'http://tgnrap.tgn.com.ar:8088/tgn-auditoria-back',
 user: {
    username:"vrnteam@vreadynow.com",
    password:"larealidadesvirtual"
     }

    



};

