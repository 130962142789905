<div *ngIf="!icon">
  <div [id]="'cont-'+index" class="cont" [attr.data-pct]="val">
    <svg [id]="'svg-'+index" [class]="'svg '+color" width="50" height="50" viewPort="0 0 25 25" version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <circle r="22.5" cx="25" cy="25" fill="transparent" stroke-dashoffset="0" stroke-dasharray="141.37"></circle>
      <circle [id]="'bar-'+index" [class]="'bar '+color" [ngStyle]="{'stroke-dashoffset': stroke}" r="22.5" cx="25"
        cy="25" fill="transparent" stroke-dasharray="141.37" stroke-dashoffset="0"></circle>
    </svg>
  </div>
</div>


<div *ngIf="icon">
  <div [id]="'cont-'+index" class="cont" [attr.data-pct]="val">
    <i class="clock outline icon"></i>
  </div>
</div>