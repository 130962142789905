<div class="ui grid">
    <div class=" height  wide column ">
        <p class='font-gray label-puntaje '> {{data.question}}</p>
    </div>
    <div class=" three  wide column center">
        <div class="ui input ">
            <input  [disabled]="isRole"  type="number" step=".01" min='0' [(ngModel)]="score"  (change)="change()">
        </div>
    </div>
    <div class=" three  wide column center">
        <form class="ui form">
            <div class="field">
                <input *ngIf="data.response_expected!=''" [value]="data.response_expected" disabled >
                <input *ngIf="data.response_expected==''" value="N/A" disabled >
            </div>
        </form>
    </div>
</div>


<div class="ui grid" style="margin: 0px!important;">
    <div class=" fourteen  wide column " style="padding: 0px!important;">
        <div class="ui divider "></div>
    </div>
</div>