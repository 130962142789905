import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiServerService } from 'src/app/core/services/api/api-server.service';
import { UsuarioService } from 'src/app/core/services/usuario/usuario.service';

@Component({
  selector: 'app-model-comentario',
  templateUrl: './model-comentario.component.html',
  styleUrls: ['./model-comentario.component.scss']
})
export class ModelComentarioComponent implements OnInit {
  comentarios: any[] = []
  detalle: any;
  type: string;
  _id: string;
  comentario: string;
  fecha = new Date();
  nro:string;
  constructor(private usuarioServices: UsuarioService, private _snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public data: { nro:string,_id: string, detalle: any, comentarios: any[], type: string }, private service: ApiServerService) {
    this.type = data.type
    this._id = data._id
    this.nro = data.nro
    this.detalle = data.detalle
    this.get()
  }

  ngOnInit(): void {
  }

  post() {
    if (this.comentario && this._id && this.nro) {
      let data = {
        code: this._id,
        nroOT: this.nro,
        description: this.comentario,
        date: new Date().toISOString(),
        user: this.usuarioServices.getUsername()
      }
      this.service.newComentario(data).subscribe(
        res => {
          this._snackBar.open('Comentario guardado correctamente', 'x', {
            duration: 6000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['green-snackbar'],
          });
          this.comentario=undefined
          this.get()
        },
        error => {
          this._snackBar.open('Ocurrio un error al guardar el comentario', 'x', {
            duration: 6000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['error-snackbar'],
          });
        }
      )
    } else {
      if (!this.comentario) {
        this._snackBar.open('El comentario no puede estar vacio', 'x', {
          duration: 6000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['red-snackbar'],
        });

      }
      if (!this._id || !this.nro) {
        this._snackBar.open('Ocurrio un error al guardar el comentario', 'x', {
          duration: 6000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['red-snackbar'],
        });

      }
    }

  }

  get() {
    this.service.getComentario(this._id).subscribe(
      res => {
        this.comentarios = res.commentsDTOs;
      },
      error => {
      }
    )
  }
}
