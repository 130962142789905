export class FiltroActividades{
    numero:any;
    planta:any;
    fecha:Date;
    descripcion:any;
    resultado:any;
    estado:any;
    max=100;
    min=0;
    anio:any;
}