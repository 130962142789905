<div style="display: none;">

    <div class="PDF-Aux">
        <div style='overflow-x: auto;' style="margin-bottom: 2em;">
            <table class="ui celled structured table unstackable"
                style="border-color: black !important; margin-bottom: 1em; margin-top: 1em;">

                <tbody>
                    <tr>
                        <td class='label ' style="width: auto; border-color: black !important;"><span
                                style="font-weight: 700 !important; color: black !important; font-size: 1em !important; margin-left: 4px !important; ">Emitido
                                por:</span></td>
                        <td class='label ' style="width: 100%; border-color: black !important;"> <span
                                style="font-weight: 700 !important; color: black !important; font-size: 1em !important; margin-left: 4px !important; ">{{formularioValue.emitido}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td class='label ' style="border-color: black !important;"><span
                                style="font-weight: 700 !important; color: black !important; font-size: 1em !important; margin-left: 4px !important; ">Destinatarios:</span>
                        </td>
                        <td class='label ' style="border-color: black !important;"> <span
                                style="font-weight: 700 !important; color: black !important; font-size: 1em !important; margin-left: 4px !important; ">{{formularioValue.destinatarios}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td class='label ' style="border-color: black !important;"><span
                                style="font-weight: 700 !important; color: black !important; font-size: 1em !important; margin-left: 4px !important; ">CC:</span>
                        </td>
                        <td class='label ' style="border-color: black !important;"> <span
                                style="font-weight: 700 !important; color: black !important; font-size: 1em !important; margin-left: 4px !important; ">{{formularioValue.cc}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td class='label ' style="border-color: black !important;"><span
                                style="font-weight: 700 !important; color: black !important; font-size: 1em !important; margin-left: 4px !important; ">Fecha:</span>
                        </td>
                        <td class='label' style="border-color: black !important;"><span
                                style="font-weight: 700 !important; color: black !important; font-size: 1em !important; margin-left: 4px !important; ">{{fecha
                                | date: 'dd/MM/yyyy'}}</span></td>
                    </tr>

                </tbody>
            </table>
        </div>
        <div style="border-top: 1px solid black; padding-top: 3%;">
            <div style="margin-top:1em;">
                <h4 style="text-decoration: underline;">OBJETIVO:</h4>
                <p>Ampliar información textual y grafica de las observaciones realizadas durante la Evaluación Técnica
                    de planta, tanto de aquellas que fueron informadas a través de un Aviso, como así tambien aquellas
                    que no ameritan la creación de un Aviso de Mtto. pero que si requieren corrección. Sugerir las
                    acciones a tomar para el tratamiento de los desvíos encontrados.</p>
            </div>
        </div>

        <div>
            <div *ngFor="let equipo of elementosEncontrados">
                <table class="ui celled structured table unstackable"
                    style="border-color: black !important; margin-bottom: 30px !important;"
                    *ngFor="let element of equipo.items; index as i;">
                    <tbody>
                        <tr style="border-color: black !important;" *ngIf="i==0">
                            <td style="border:1px solid black !important; width: auto;width: 200px;"><span class='negrita2'>EQUIPO
                                    :</span></td>
                            <td style="border-color: black !important; ">
                                <span class='negrita2'>{{element.equipo}}</span>
                            </td>
                        </tr>
                        <tr style="border-color: black !important;" *ngIf="i==0">
                            <td style="border-color: black !important;width: 200px;"><span class='negrita2'>Protocolo:</span></td>
                            <td style="border-color: black !important;"> <span
                                    class='negrita2'>{{element.protocolo}}</span></td>
                        </tr>
                        <tr *ngIf="i==0">
                            <td style="border-color: black !important;width: 200px;"><span class='negrita2'>Puntaje equipo:</span>
                            </td>
                            <td style="border-color: black !important;"> <span
                                    class='negrita2'>{{element.puntaje}}</span></td>
                        </tr>
                        <tr>
                            <td style="border-color: black !important;width: 200px;"><span class='negrita2 blue-txt'
                                    style="color: blue !important;">Avisos ET:</span></td>
                            <td style="border-color: black !important;"><span class='negrita2 blue-txt'
                                    style="color: blue !important;">{{element.aviso}}</span></td>
                        </tr>
                        <tr class="">
                            <td style="border-color: black !important;width: 200px;"><span class='negrita2 red-txt'
                                    style="color: red !important;">Observaciones:</span></td>
                            <td style="border-color: black !important;"><span class='negrita2 red-txt'
                                    style="color: red !important;">{{element.observaciones}}</span></td>
                        </tr>
                        <tr class="">
                            <td style="border:1px solid black !important;width: 200px;"><span class='negrita2 green-txt'
                                    style="color: green !important;">Acciones a tomar:</span></td>
                            <td style="border:1px solid black !important;"><span class='negrita2 green-txt'
                                    style="color: green !important;">{{element.accion}}</span></td>
                        </tr>
                        <tr>
                            <td *ngIf="element.img" colspan="2" style="border:1px solid black !important;">
                                <div  style="margin:5px;width: 20%; display: inline-block;" *ngFor="let i of element.img">
                                    <!-- <p>{{element.titulo}}</p> -->
                                    <img class="img-pdf" style="width: 100%;" [src]="i" />
                                    <!-- <p>{{this.descripcionFoto}}</p> -->
                                </div>
                            </td>

                        </tr>

                    </tbody>
                </table>
            </div>

        </div>
    </div>
</div>