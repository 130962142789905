<div class="" style="width: 25vw;" >
    <form [formGroup]="formulario" id="">
        <div style='overflow-x: auto;' style="margin-bottom: 2em;" *ngIf="numero_ot">
            <table class="ui  unstackable" style="width: 100%;">
                <thead>
                    <tr style="width: 100%; text-align-last: right;"> <i mat-dialog-close class="close icon"></i></tr>
                    <tr>
                        <th colspan="2" class="center aligned ">
                            <h3>Información auxiliar</h3>
                        </th>
                       
                    </tr>
                </thead>
                <tbody >
                    <tr>
                        <td colspan="2" class='label'>Aviso ET:</td>
                       
                    </tr>
                    <tr>
                        <td colspan="2">
                            <div class="ui form" *ngIf="isRole">
                                <textarea name="" id="" rows="1" formControlName="notice" class="ng-pristine ng-valid ng-touched textArea-modal"  [attr.readonly]="!isRole || finished ? true : null"    [ngClass]="(finished) ? 'readonly' : ''" ></textarea>
                            </div>
                            <div *ngIf="!isRole">
                                <span>
                                    {{ formValues.notice ? formValues.notice : '-' }}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class='label'>Observaciones:</td>
                       
                    </tr>
                    <tr>
                        <td colspan="2">
                            <div class="ui form" *ngIf="isRole">
                                <textarea name="" id="" rows="1" formControlName="observation" class="ng-pristine ng-valid ng-touched textArea-modal" [attr.readonly]="!isRole || finished ? true : null"    [ngClass]="(finished) ? 'readonly' : ''" ></textarea>
                            </div>

                            <div *ngIf="!isRole">
                                <span>
                                    {{ formValues.observation ? formValues.observation : '-' }}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class='label'> Acciones a tomar:</td>
                       
                    </tr>
                    <tr>
                        <td colspan="2">
                            <div class="ui form" *ngIf="isRole">
                                <textarea name="" id="" rows="1" formControlName="action" class="ng-pristine ng-valid ng-touched textArea-modal" [attr.readonly]="!isRole || finished ? true : null"    [ngClass]="(finished) ? 'readonly' : ''" ></textarea>
                            </div>
                            <div *ngIf="!isRole">
                                <span>
                                    {{ formValues.action ? formValues.action : '-' }}
                                </span>
                            </div>
                        </td>
                    </tr>
                   
                    
                </tbody>
              
            </table>
        </div>
        <div class="" style="text-align: center;" >
            <button *ngIf="hayDatos && isRole" [disabled]="formulario.invalid || finished || !isRole"
                class="ui  button  primary"
                (click)="post()">
                Actualizar
            </button>
            <button *ngIf="!hayDatos && isRole"  [disabled]="formulario.invalid || finished || !isRole"
                class="ui  button  primary"
                (click)="post()">
                Guardar
            </button>
        </div>

        
        
    </form>

</div>