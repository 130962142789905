import { Component, Inject, Input, OnInit, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UsuarioService } from 'src/app/core/services/usuario/usuario.service';
import { GalleryImage } from 'src/app/shared/models/galeryImage';
import { environment } from 'src/environments/environment';
import { ModalObservacionesComponent } from '../modal-observaciones/modal-observaciones.component';
declare var $: any;

@Component({
  selector: 'app-modal-avisos',
  templateUrl: './modal-avisos.component.html',
  styleUrls: ['./modal-avisos.component.scss']
})

export class ModalAvisosComponent implements OnInit {
  protocolos: any;
  auxiliarAvisos: any[] = [];
  detalles: any[] = [];
  token: any;
  protocoloTabla: { [key: string]: any[] } = {};
  constructor(
    public dialog: MatDialog,
    private usuarioServices: UsuarioService,
    @Inject(MAT_DIALOG_DATA) public data: { protocolos: any[] },
  ) {
    this.token = this.usuarioServices.getToken();


    data.protocolos.forEach(p => {
      p.datos.equipos.forEach(e => {
        if (e.actividades) {
          e.actividades.forEach(a => {
            a.preguntas.forEach(i => {
              if (i.Instruction_Id.startsWith("AvisoMtto")) {
                this.avisos(i, p.protocolo, e, a);
              }
              if (i.Evidence_Type == "" && i.Instruction_Id == 'DetAvisoMttoOtros') {
                this.detalles.push(i)
              }
            });
          })
        }


      })
    })



    /*  this.auxiliarAvisos.forEach(elemento => {
       const protocolo = elemento.protocolo;
       if (!this.protocoloTabla[protocolo]) {
         this.protocoloTabla[protocolo] = [];
       }
       this.protocoloTabla[protocolo].push(elemento);
     }); */

    this.auxiliarAvisos.forEach(elemento => {
      if ((elemento.tipo == "M2" || elemento.tipo == "M1")) {
        const protocolo = elemento.protocolo;
        let equipo = elemento.equipo;
        const descripcion = elemento.descripcionEquipo;
        if(descripcion){
          equipo = (equipo + " - " + descripcion)

        }
        if (!this.protocoloTabla[protocolo]) {
          this.protocoloTabla[protocolo] = [];
        }
        if (!this.protocoloTabla[protocolo][equipo]) {
          this.protocoloTabla[protocolo][equipo] = [];
        }


        this.protocoloTabla[protocolo][equipo].push(elemento);
      }
    });




  }
  avisos(item: any, protocolo: any, equipo: any, actividad: any) {
    for (var key in item.Status) {
      if (key.startsWith("AvisoMtto") && item.Status[key].Observed !== 'N/A') {
        let obj = {
          protocolo: protocolo,
          equipo: equipo.equipo,
          descripcionEquipo: equipo.descripcion,
          actividad: actividad.actividad,
          tipo: item.Status[key].Observed,
          descripcion: this.buscarDetAvisoMtto(item, key, actividad),
          observaciones: this.buscarObservacionAvisoMtto(item.Instruction_Id, item.Instruction_Dsc, item.Mission_Id, actividad),
        }
        //console.log(obj)

        this.auxiliarAvisos.push(obj)
      }
    }

  }
  buscarDetAvisoMtto(result: any, id, actividad: any) {
    let indexDet = 0;
    let detalles = {
      detalle: null,
      persona: "",
      fecha: ""
    }
    for (var key in result.Status) {
      if (key == "AvisoMttoOtros") {
        let filter = actividad.mensajes.filter(d => d.Evidence_Type == "" && d.Instruction_Id == 'DetAvisoMttoOtros')
        let index = filter.length - 1;
        detalles.detalle = filter[index].Status.DetAvisoMttoOtros.Observed;
        detalles.persona = filter[index].Who[0].username;
        detalles.fecha = filter[index].When;
        indexDet++;
      }
      else
        if (key.startsWith("Det" + id)) {
          detalles.detalle = result.Status[key].Observed;
          detalles.persona = result.Who[0].username;
          detalles.fecha = result.When;
          indexDet++;
        }
    }

    return detalles;
  }
  buscarObservacionAvisoMtto(instruction_id: any, instruction_Dsc: any, mission_id: any, actividad) {
    let observaciones: any = {}
    actividad.observaciones.filter(a => a.Mission_Id == mission_id).forEach(m => {
      if ((Array.isArray(m.SubjectPart) && m.SubjectPart.includes(instruction_id) || m.Instruction_Id == instruction_id)) {
        let observacion = (m.Status.Observacion)
        observaciones.lista = (!observaciones.lista) ? observaciones.lista = [] : observaciones.lista;
        observaciones.lista.push(observacion)
        observaciones.id = m.Mission_Id;
      }
    })
    return observaciones;
  }
  ngOnInit(): void {
  }
  posicionAux: number;
  showObservaciones(data, titulo) {
    let info = []
    data.lista.forEach(item => {
      let observacion: any = {}
      observacion.gallery = [];
      let fotos = item.Evidence;
      observacion.descripcion = item.Observed;
      var i = 0;
      fotos.forEach(f => {
        const image = new GalleryImage();
        image.alt = f;
        image.src = `${environment.apiUrl}/api/instance/instancia-de-prueba/mission/Mission_${data.id}/file/${f}?token=${this.token}`;
        image.position = (this.posicionAux) ? this.posicionAux : i;
        observacion.gallery.push(image);
        i = i + 1;
      })
      info.push(observacion)

    })

    this.dialog.open(ModalObservacionesComponent, { panelClass: 'custom-dialog-container', data: { info: info } });
  }

}
