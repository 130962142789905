<div class="ui container" *ngIf="data">
    <div class="ui grid">
        <div class=" sixteen  wide column ">
            <h2 class='title-style '>ADMINISTRACIÓN DE PUNTAJES</h2>
        </div>
    </div>
    <div class="ui grid ">
        <div class="twelve wide column ">
            <h2 class='negrita subtitle' style='margin-top: 0.5rem!important;margin-left: 0px!important;'>PUNTAJES
                RELATIVOS ENTRE
                PROTOCOLOS</h2>
            <div style="height: 3em;" class="mt">
                <div class="ui grid">
                    <div class=" twelve  wide column ">
                    </div>
                    <div class=" two  wide column center">
                        <span class='font-gray label-puntaje '> Puntos</span>
                    </div>
                </div>
            </div>
            <div *ngFor="let item of data">
                <div class="ui grid">
                    <div class=" twelve  wide column ">
                        <p class='font-gray label-puntaje '> {{item.description}}</p>
                    </div>

                    <div class=" two  wide column ">
                        <div class="ui input ">
                            <input  type="number" step=".01"
                                [id]="'score-'+item.id" [value]="item.score" (change)="changeScore(item)"
                                placeholder="0"  [disabled]="isRole"  >
                        </div>
                    </div>

                </div>
                <div class="ui grid">
                    <div class=" fourteen  wide column " style="padding: 0px!important;">
                        <div class="ui divider "></div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="ui grid mt">
        <div class="sixteen wide column ">
            <h2 class='negrita subtitle' style='margin-top: 0px!important;margin-left: 0px!important;'>PUNTAJES
                RELATIVOS
                PREGUNTAS</h2>
            <div class="ui accordion" *ngFor="let item of data;index as i">
                <div style='margin-top: 15px;' *ngIf="item.activities.length>0">
                    <div [id]="'title-'+i" class="title" (click)="active(i)">
                        <i class="dropdown icon"></i>
                        {{item.description}}

                    </div>
                    <div [id]="'content-'+i" class="content">
                        <span class="label-puntaje" style="color:grey;">Puntos utilizados:
                            <span style="color:black;" [id]="'puntaje-'+i"> {{item.puntaje}}</span>
                        </span>

                        <div *ngFor="let actividad of item.activities">
                            <div style="height: 5em;">

                                <div class="ui grid">
                                    <div class=" height  wide column ">
                                    </div>

                                    <div class=" three  wide column center" style="margin-bottom: 1em;">


                                    </div>
                                    <div class=" three  wide column center">
                                    </div>
                                </div>
                                <div class="ui grid">
                                    <div class=" height  wide column ">
                                        <h2 class='title-actividad subtitle'>{{actividad.description}}</h2>
                                    </div>
                                    <div class=" three  wide column center">

                                        <span class='font-gray label-puntaje-2 ui input '> Puntos</span>
                                    </div>
                                    <div class=" three  wide column center">
                                        <span class='font-gray label-puntaje-2  '> Respuesta esperada</span>
                                    </div>
                                </div>
                            </div>

                            <div class="ui grid">
                                <div class=" fourteen  wide column ">
                                    <div class="ui divider "></div>
                                </div>
                            </div>
                            <app-preguntas [isRole]="isRole" (changeEmitter)='onChangeEvent($event ,i)'
                                *ngFor="let p of actividad.instructions" [descriptionProtocol]="item.description"
                                [descriptionActivity]="actividad.description" [data]="p" >
                            </app-preguntas>
                        </div>



                    </div>
                </div>
            </div>



        </div>
    </div>
    <div class="ui grid">
        <div class=" fourteen  wide column " style="padding: 0px!important;">
            <div class="ui divider "></div>
        </div>
    </div>
    <div class="ui grid">
        <div class="extra content">
            <button *ngIf="rol.includes('Jefe de Análisis de Condición')"  (click)="guardar()"
                class="ui right labeled icon button button-filter primary">
                <i class="edit
                          icon"></i>
                Guardar
            </button>

            <button class="ui right labeled icon button button-filter primary" (click)="goHome()">
                <i class="home
                          icon"></i>
                Volver
            </button>
        </div>
    </div>
</div>