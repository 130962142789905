import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShellRoutingModule} from './shell-routing.module';
import {ShellComponent} from './pages/shell/shell.component';
import {HeaderComponent} from './pages/header/header.component';
import {MainComponent} from './pages/main/main.component';
import {FooterComponent} from './pages/footer/footer.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import {HomeModule} from '../home/home.module';
import {PageNotFoundComponent} from '../home/components/page-not-found/page-not-found.component';
import {ErrorComponent} from '../home/components/error/error.component';
import {GoBackDirective} from '../../shared/directives/go-back.directive';
import {CloseMenuDirective} from '../../shared/directives/close-menu.directive';
import { AngularMaterialModule } from 'src/app/shared/angular-material/angular-material.module';


@NgModule({
  declarations:[
    ShellComponent, HeaderComponent, MainComponent, FooterComponent, PageNotFoundComponent, ErrorComponent,
    GoBackDirective, CloseMenuDirective
  ],
  imports:[
    CommonModule, ShellRoutingModule,AngularMaterialModule,
    MatCardModule, MatSidenavModule, MatToolbarModule, MatListModule, MatIconModule, MatButtonModule, MatSnackBarModule, MatExpansionModule
  ],
  exports:[ShellComponent, ShellRoutingModule, CloseMenuDirective, GoBackDirective]
})
export class ShellModule {
}
