import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ApiServerService } from 'src/app/core/services/api/api-server.service';
import { ModalInformacionAuxiliarComponent } from '../modal-informacion-auxiliar/modal-informacion-auxiliar.component';
import { GalleryImage } from 'src/app/shared/models/galeryImage';
import { environment } from 'src/environments/environment';
import { UsuarioService } from 'src/app/core/services/usuario/usuario.service';
declare var $: any;

@Component({
  selector: 'app-modal-descargar-guardar-informacion-auxiliar',
  templateUrl: './modal-descargar-guardar-informacion-auxiliar.component.html',
  styleUrls: ['./modal-descargar-guardar-informacion-auxiliar.component.scss']
})
export class ModalDescargarGuardarInformacionAuxiliarComponent implements OnInit {
  id: string;
  numero_ot: any
  formulario: FormGroup;
  hayDatos: boolean
  inhabilitarBtn: boolean = false
  codigo: any
  protocolo: any
  protocolos: any
  fecha: any
  noDescargar: boolean = false
  watermark: any = "false"
  finished: boolean = false
  formularioFinalizar: FormGroup;
  item: any;
  formularioValue: any
  nroOt: any
  infoAux: any
  elementosEncontrados: any[] = []
  planta: any;
  posicionAux: number;
  token: any
  tituloFoto: any
  spinner = true;
  descripcionFoto: any
  html: string;
  gallery: GalleryImage[] = [];
  mostrarDescargar: boolean = false
  rol: any
  isRole = false;
  limit = 5;
  constructor(private _snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public data: { isRole: boolean, planta: string, id: string, nro_ot: any, protocolo: string, protocolos: any, fecha: any }, private service: ApiServerService, private rutaActiva: ActivatedRoute, private form: FormBuilder,
    public dialogRef: MatDialogRef<ModalInformacionAuxiliarComponent>, private usuarioServices: UsuarioService, public dialog: MatDialog) {


    this.protocolos = data.protocolos
    this.fecha = data.fecha
    this.id = data.id
    //this.isRole = data.isRole
    this.protocolo = data.protocolo
    this.numero_ot = data.nro_ot
    this.codigo = this.numero_ot /* + this.protocolo */
    this.nroOt = data.nro_ot
    this.token = this.usuarioServices.getToken();
    this.planta = data.planta;


    this.formulario = this.form.group({
      code: [this.codigo ? this.codigo : ''],
      region: ['', [Validators.required]],
      emitido: ['', [Validators.required]],
      destinatarios: ['', [Validators.required]],
      cc: ['', [Validators.required]],
    });
    this.formularioFinalizar = this.form.group({
      code: [this.codigo ? this.codigo : '']
    });

    this.get()
  }

  ngOnInit(): void {

  }

  post() {
    this.inhabilitarBtn = true
    this.service.updateAuxInformationsPDF(this.formulario.value).subscribe(
      res => {
        this._snackBar.open('Datos guardados correctamente', 'x', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['green-snackbar'],
        });
        this.hayDatos = true;
        this.inhabilitarBtn = false;

      },
      error => {
        this._snackBar.open('Ocurrio un error al guardar el comentario', 'x', {
          duration: 6000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['error-snackbar'],
        });
        this.inhabilitarBtn = false

      }

    )


  }

  get() {
    this.service.getAuxInformationsPDF(this.codigo).subscribe(
      res => {
        console.log(res)
        if (res.pdfAuxInformationDTOList && res.pdfAuxInformationDTOList[0]) {
          this.item = res.pdfAuxInformationDTOList[0];
          this.formulario.patchValue({
            region: res.pdfAuxInformationDTOList[0].region,
            emitido: res.pdfAuxInformationDTOList[0].emitido,
            destinatarios: res.pdfAuxInformationDTOList[0].destinatarios,
            cc: res.pdfAuxInformationDTOList[0].cc,
          });
          this.hayDatos = true
          this.finished = res.pdfAuxInformationDTOList[0].finished






        } else {
          this.hayDatos = false
          this.noDescargar = true
        }
        this.spinner = false;
        if (this.finished && !this.usuarioServices.isRoles(['Jefe de Análisis de Condición', 'Evaluador Tec.Planta'])) {
          this.isRole = true;
        } else if (this.usuarioServices.isRoles(['Jefe de Análisis de Condición', 'Evaluador Tec.Planta'])) {
          this.isRole = true;
        }
      },
      error => {
        console.log("error", error)
        this._snackBar.open('Ocurrio un error al cargar la información', 'x', {
          duration: 6000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['error-snackbar'],
        });
      }
    )
  }


  finalizar() {
    this.watermark = "true"

    this.service.finalizarAuxInfoPDF(this.formularioFinalizar.value).subscribe(
      res => {
        this._snackBar.open('Evaluación finalizada', 'x', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['green-snackbar'],
        });
        this.finished = true
        setTimeout(() => {
          this.dialogRef.close(this.finished);
        }, 2000)

      },
      error => {
        this._snackBar.open('Ocurrio un error al finalizar la evaluación', 'x', {
          duration: 6000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['error-snackbar'],
        });
        this.inhabilitarBtn = false

      }

    )


  }


  recorrerProtocolos() {
    if (this.protocolos && this.protocolos.length > 0) {
      for (const p of this.protocolos) {
        if (p.datos && p.datos.equipos && p.datos.equipos.length > 0) {
          for (const item of p.datos.equipos) {

            let aux = [];

            if (item.actividades) {
              for (let x = 0; x < item.actividades.length; x++) {
                const actividad = item.actividades[x];
                for (let z = 0; z < actividad.preguntas.length; z++) {
                  let pregunta = actividad.preguntas[z];
                  if (pregunta.Instruction_Id.startsWith("AvisoMtto")) {
                    let avisos = this.avisos(pregunta, actividad.observaciones)
                    avisos.forEach(aviso => {
                      this.buildObjAviso(item, aviso.idCode, aviso, aux);
                    })
                  } else {
                    let id = pregunta.Mission_Id + "-" + pregunta.Activity_Id + "-" + pregunta.Protocol_Id + "-" + pregunta.Instruction_Id + "-" + pregunta.Instruction_Id;
                    this.buildObj(item, id, pregunta, aux);
                  }

                }

              }
            }

            this.elementosEncontrados.unshift({ items: aux });

          }
        }
      }
    }

    this.descargarPDF()
  }
  buildObj(item, id, pregunta, aux) {
    for (let a = 0; a < this.infoAux.length; a++) {
      const element = this.infoAux[a];
      if (element.code == id) {
        let info = pregunta.preguntasInfo[0];
        let fotos = (info.fotos) ? info.fotos.Evidence : [];
        let observaciones = (pregunta.preguntasInfo[0].observaciones.lista) ? pregunta.preguntasInfo[0].observaciones.lista : []
        let urlsImg = []
        fotos.forEach(img => {
          let nombre = img;
          let src = `${environment.apiUrl}/api/instance/instancia-de-prueba/mission/Mission_${pregunta.Mission_Id}/file/${nombre}?token=${this.token}`;
          if (urlsImg.length < this.limit) urlsImg.unshift(src)
        })
        observaciones.forEach(img => {
          let nombre = img.Evidence[0];
          let src = `${environment.apiUrl}/api/instance/instancia-de-prueba/mission/Mission_${pregunta.Mission_Id}/file/${nombre}?token=${this.token}`;
          if (urlsImg.length < this.limit) urlsImg.unshift(src)
        })
        let elemento = {
          equipo: " Equipo " + item.equipo + " " + item.descripcion,
          protocolo: pregunta.Protocol_Id.replace(/_/g, ' '),
          puntaje: item.puntaje,
          aviso: element.notice,
          observaciones: element.observation,
          accion: element.action,
          img: urlsImg,
          instruction_seq: element.instruction_seq

        }

        aux.unshift(elemento)
      }

    }
  }
  buildObjAviso(item, id, pregunta, aux) {
    for (let a = 0; a < this.infoAux.length; a++) {
      const element = this.infoAux[a];
      if (element.code == id) {
        let observaciones = (pregunta.observaciones.lista) ? pregunta.observaciones.lista : []
        let urlsImg = []

        observaciones.forEach(img => {
          let nombre = img.Evidence[0];
          let src = `${environment.apiUrl}/api/instance/instancia-de-prueba/mission/Mission_${pregunta.Mission_Id}/file/${nombre}?token=${this.token}`;
          if (urlsImg.length < this.limit) urlsImg.unshift(src)
        })
        let elemento = {
          equipo: " Equipo " + item.equipo + " " + item.descripcion,
          protocolo: pregunta.Protocol_Id.replace(/_/g, ' '),
          puntaje: item.puntaje,
          aviso: element.notice,
          observaciones: element.observation,
          accion: element.action,
          img: urlsImg,
          instruction_seq: element.instruction_seq

        }

        aux.unshift(elemento)
      }

    }
  }
  descargarPDF() {
    //this.accionDescargar.emit(true);
    const fechaObj = new Date(this.fecha);
    const dia = fechaObj.getDate();
    const mes = fechaObj.getMonth() + 1;
    const año = fechaObj.getFullYear();
    const fechaFormateada = `${dia < 10 ? '0' : ''}${dia}-${mes < 10 ? '0' : ''}${mes}-${año}`;

    let datosBody = {
      auxilarInfo: true,
      region: this.formularioValue.region,
      emitido: this.formularioValue.emitido,
      cc: this.formularioValue.cc,
      destinatarios: this.formularioValue.destinatarios,
      fecha: fechaFormateada,
      nroIdenticacion: this.planta + "-" + fechaFormateada,
      nroIdenticacionTitle: this.planta,
      code: this.nroOt
      //watermark: this.formularioValue.watermark
    }
    let title = "InformacionAuxiliar-" + this.nroOt
    setTimeout(() => {

      this.html = (document.getElementById("PDFAux").children[0].innerHTML);
      this.usuarioServices.generarPDFInfoAux(this.html, datosBody).subscribe(data => {
        //this.accionDescargar.emit(false);
        this.mensaje('PDF listo!', '');
        this.downloadFile(data.body, title + '.pdf')
        this.dialog.closeAll();
        $("#spinner").css("display", "none");

      });
    }, 0);

  }
  downloadFile(blob, fileName) {
    const link = document.createElement('a');
    this.blobToBase64(blob, (base64) => {
      link.href = base64;
      link.download = fileName;
      link.click();
      window.close()

    });
  };

  blobToBase64(blob, callback) {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64 = reader.result;
      callback(base64);
    };
    reader.readAsDataURL(blob);

  };

  mensaje(message, action) {
    this._snackBar.open(message, action, {
      duration: 6500,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['gray-snackbar'],
    });
  }
  showFoto(data, titulo): any {
    this.tituloFoto = titulo
    this.gallery = [];
    let fotos = data.Evidence;
    this.descripcionFoto = data.Observed;
    var i = 0;
    fotos.forEach(f => {
      const image = new GalleryImage();
      image.alt = f;
      image.src = `${environment.apiUrl}/api/instance/instancia-de-prueba/mission/Mission_${data.id}/file/${f}?token=${this.token}`;
      image.position = (this.posicionAux) ? this.posicionAux : i;
      this.gallery.push(image);
      i = i + 1;
    })
    return this.gallery
    //this.dialog.open(GalleryModalComponent, { panelClass: 'custom-dialog-container', data: { titulo: titulo, descripcion: descripcion, gallery: this.gallery, position: 0 } });
  }

  getListadoInfo() {
    this.mostrarDescargar = true
    $("#spinner").css("display", "block");
    this.formularioValue = this.formulario.value
    this.service.updateAuxInformationsPDF(this.formulario.value).subscribe(resSave => {
      this.service.getAuxInformationsByOT(this.nroOt).subscribe(
        res => {
          this.infoAux = res.auxInformationDTOs
          this.recorrerProtocolos();

        },
        error => {
          console.log("error", error)
          this._snackBar.open('Ocurrio un error al cargar la información', 'x', {
            duration: 6000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['error-snackbar'],
          });
        }
      )
    })

  }


  avisos(result: any, observaciones: any[]) {
    if (result.Protocol_Id == "CAI" && result.Activity_Id == "Otros") {
      console.log(result)

    }
    let array: any = []
    for (var key in result.Status) {

      if (key.startsWith("AvisoMtto") && result.Status[key].Observed !== 'N/A') {

        let obj = {
          pregunta: result.Instruction_Dsc,
          aviso: result.Status[key].Observed,
          Mission_Id: result.Mission_Id,
          Activity_Id: result.Activity_Id,
          Protocol_Id: result.Protocol_Id,
          Instruction_Id: result.Instruction_Id,
          id: key,
          tieneInfoAux: false,
          idCode: '',
          observaciones: this.buscarObservacionAvisoMtto(result.Instruction_Id, result.Mission_Id, observaciones),

        }
        let id = obj.id + "-" + obj.Mission_Id + "-" + obj.Activity_Id + "-" + obj.Protocol_Id + "-" + obj.Instruction_Id + "-" + obj.Instruction_Id;
        obj.idCode = id
        if (this.infoAux) {
          for (let i = 0; i < this.infoAux.length; i++) {
            const itemInfo = this.infoAux[i];
            if (itemInfo.code == id) {
              obj.tieneInfoAux = true
              break;
            } else {
              obj.tieneInfoAux = false
            }
          }
        }
        if (obj.tieneInfoAux) array.push(obj)

      }
    }
    return array;

  }

  buscarObservacionAvisoMtto(instruction_id, mission_id, listaObservaciones: any[]) {
    let observaciones: any = []
    listaObservaciones.filter(a => a.Mission_Id == mission_id).forEach(m => {
      if ((Array.isArray(m.SubjectPart) && m.SubjectPart.includes(instruction_id) || m.Instruction_Id == instruction_id)) {
        let observacion = (m.Status.Observacion)
        observaciones.lista = (!observaciones.lista) ? observaciones.lista = [] : observaciones.lista;
        observaciones.lista.push(observacion)
        observaciones.id = m.Mission_Id;
      }
    })
    return observaciones;
  }

}

