import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiServerService } from 'src/app/core/services/api/api-server.service';
import { UsuarioService } from 'src/app/core/services/usuario/usuario.service';
declare var $: any;

@Component({
  selector: 'app-administracion-de-puntajes',
  templateUrl: './administracion-de-puntajes.component.html',
  styleUrls: ['./administracion-de-puntajes.component.scss']
})
export class AdministracionDePuntajesComponent implements OnInit {
  data: any;
  mensajePuntaje = ""
  puntajesDisponibles = []
  rol= []
  constructor(private usuarioService:UsuarioService,private router: Router, private service: ApiServerService, private _snackBar: MatSnackBar) {
    this.role()
    this.service.getPuntajes().subscribe(res => {
      this.data = res.protocolDTOs;
      this.data.forEach(p => {
        let sum = 0;
        p.activities.forEach(a => {
          a.instructions.forEach(i => {
            sum = sum + i.score;
          })
        })
        p.puntaje = sum.toFixed(2);
      });
      $('#spinner-load').css("display", "none");

    })
  }

  ngOnInit(): void {
  }

  verificarPuntajes() {
    this.mensajePuntaje = ""
    let count = 0;
    let error = 0;
    this.data.forEach(p => {
      let puntaDisponible = this.calcularPuntajeDisponible(p);
      count++;
      let div = document.getElementById("puntaje-" + count)

      if (puntaDisponible > 100) {
        this.mensajePuntaje = this.mensajePuntaje + "El protocolo " + p.description + " supera los 100 puntos /"
        error++;
      }
    })
    return error;
  }
  ngAfterViewInit(): void {
    $('.ui.accordion').accordion();
  }
  changeScore(item: any) {
    let score = (document.getElementById("score-" + item.id) as HTMLInputElement).value
    item.score = Number(score)

  }
  active(i: number) {
    let title = document.getElementById("title-" + i)
    let content = document.getElementById("content-" + i)
    if (title.classList.contains("active") || content.classList.contains("active")) {
      title.classList.remove("active")
      content.classList.remove("active")
    } else {
      title.classList.add("active")
      content.classList.add("active")
    }

  }
  goHome() {
    this.router.navigate(['/']);
  }
  calcularPuntajeDisponible(p: any) {
    let sum = 0;
    p.activities.forEach(a => {
      a.instructions.forEach(i => {
        sum = sum + i.score;
      })
    })
    p.puntaje = sum.toFixed(2);
    return sum;
  }
  onChangeEvent(event, i) {
    let protocolo = this.data.find(d => d.description == event.prococolo)
    let actividad = protocolo.activities.find(d => d.description == event.actividad)
    let item = actividad.instructions.find(d => d.id == event.id)
    if (item) {
      item.score = event.score
      let puntaDisponible = this.calcularPuntajeDisponible(protocolo);
      let div = document.getElementById("puntaje-" + i)

      if (puntaDisponible > 100) {
        div.classList.add("error")
        this._snackBar.open('La suma de los puntajes no puede ser mayor a 100', 'x', {
          duration: 6000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['error-snackbar'],
        });
      } else {
        div.classList.remove("error")

      }
    }

  }

  guardar() {
    let errorPuntaje = this.verificarPuntajes()

    if (errorPuntaje > 0) {
      this._snackBar.open(this.mensajePuntaje, 'x', {
        duration: 6000000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
        panelClass: ['red-snackbar'],
      })
    } else {
      this.service.updatePuntajes(this.data).subscribe(
        res => {
          this._snackBar.open('Puntajes editados correctamente', 'x', {
            duration: 6000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['green-snackbar'],
          });

        },
        error => {
          this._snackBar.open('Ocurrio un error al editar  los puntajes', 'x', {
            duration: 6000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['red-snackbar'],
          });
        });
    }

  }
  isRole=false;
  role() {
    let options = ['Jefe de Análisis de Condición'];
    this.isRole = !this.usuarioService.isRoles(options);
  }
}
