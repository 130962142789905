import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiServerService } from 'src/app/core/services/api/api-server.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { UsuarioService } from 'src/app/core/services/usuario/usuario.service';

@Component({
  selector: 'app-modal-informacion-auxiliar',
  templateUrl: './modal-informacion-auxiliar.component.html',
  styleUrls: ['./modal-informacion-auxiliar.component.scss']
})
export class ModalInformacionAuxiliarComponent implements OnInit {
  isRole = false;
  id: string;
  numero_ot: any
  formulario: FormGroup;
  hayDatos: boolean
  inhabilitarBtn: boolean = false
  finished: boolean;
  item: any;
  rol: string[]
  formValues: any = {};
  constructor(private usuarioService: UsuarioService, private _snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public data: { isRole: boolean, id: string, nro_ot: string, finished: boolean, equipo: any, instruction_Seq: any }, private service: ApiServerService, private rutaActiva: ActivatedRoute, private form: FormBuilder,
    public dialogRef: MatDialogRef<ModalInformacionAuxiliarComponent>, public dialog: MatDialog) {
    this.rol = this.usuarioService.getRoles();
    this.isRole = data.isRole;

    this.id = data.id
    this.numero_ot = data.nro_ot
    this.finished = data.finished
    this.formulario = this.form.group({
      code: [data.id ? data.id : ''],
      notice: ['', [Validators.required]],
      action: ['', [Validators.required]],
      nroOT: [data.nro_ot ? data.nro_ot : ''],
      observation: ['', [Validators.required]],
      equipo: [data.equipo ? data.equipo : ''],
      instruction_seq: [data.instruction_Seq ? data.instruction_Seq : ''],
    });
    this.get()
  }

  ngOnInit(): void {
  }

  abrirModal() {
    const dialogRef = this.dialog.open(ModalInformacionAuxiliarComponent, {
      // Configuración del modal...
    });


  }

  post() {
    this.inhabilitarBtn = true
    if (this.id) {
      this.service.updateAuxInformations(this.formulario.value).subscribe(
        res => {
          this._snackBar.open('Datos guardados correctamente', 'x', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['green-snackbar'],
          });

          setTimeout(() => {
            this.dialogRef.close();
          }, 3000);
          let guardado = true
          this.dialogRef.close(guardado);

        },
        error => {
          this._snackBar.open('Ocurrio un error al guardar el comentario', 'x', {
            duration: 6000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['error-snackbar'],
          });
          this.inhabilitarBtn = false

        }

      )
    }

  }

  get() {
    this.service.getAuxInformations(this.id).subscribe(
      res => {
        this.item = res.auxInformationDTOs[0];
        if (res.auxInformationDTOs && res.auxInformationDTOs[0]) {
          this.formulario.patchValue({
            notice: res.auxInformationDTOs[0].notice,
            action: res.auxInformationDTOs[0].action,
            observation: res.auxInformationDTOs[0].observation,
          });
          this.formValues = this.formulario.value;
          this.hayDatos = true

        } else {
          this.hayDatos = false
        }
      },
      error => {
        this._snackBar.open('Ocurrio un error al cargar la información', 'x', {
          duration: 6000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['error-snackbar'],
        });
      }
    )
  }

}
