import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { tap } from 'rxjs/operators';
import { User } from 'src/app/shared/models/user';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private http: HttpClient) {
    //si puedo cambiar los localStorage con un servicio al back mejor 
  }

  generarPDF2(html: string, title: string, state: string, score: number) {
    let watermark = true;
    if (state == "Finalizado") {
      watermark = false
    }
    let datos = {
      "html": `${html}`,
      "type": `Evaluación completa`,
      "state": `${state}`,
      "score": `${score}`,
      "watermark": `${watermark}`
    }

    return this.http.post(environment.urlBack + '/pdf/get', datos, { reportProgress: true, responseType: 'blob' as 'blob', observe: 'response' }).pipe(tap(
      (res: any) => {
        return res;
      }));

  }
  generarPDFInfoAux(html: string,  datosBody: any) {

 
    let datosAEnviar = {
      "html": `${html}`,
      "auxilarInfo": true,
      "region":`${datosBody.region}`,
      "emitido":`${datosBody.emitido}`,
      "cc": `${datosBody.cc}`,
      "destinatarios": `${datosBody.destinatarios}`,
      "fecha":`${datosBody.fecha}`,
      "nroIdenticacion": `${datosBody.nroIdenticacion}`,
      "nroIdenticacionTitle": `${datosBody.nroIdenticacionTitle}`,
      "code": `${datosBody.code}`
    }
    
    return this.http.post(environment.urlBack + '/pdf/get', datosAEnviar, { reportProgress: true, responseType: 'blob' as 'blob', observe: 'response' }).pipe(tap(
      (res: any) => {
        return res;
      }));

  }


  setData(data: any) {
    localStorage.setItem('usernameTgnET', data.userName)
    localStorage.setItem('rolesTgnET', JSON.stringify(data.userInfoDTOs))

  }

  getUsername() {
    return localStorage.getItem('usernameTgnET');
  }


  setToken(token: string) {
   
    localStorage.setItem('tokenTgnET', token)
  }
  isLoggedIn() {
    return localStorage.getItem('rolesTgnET');
  }

  clear() {
    localStorage.clear();
  }
  getPlantas() {
    return ['*'];
  }
  getRoles() {
    let info = JSON.parse(localStorage.getItem('rolesTgnET')) ;
    let array = [];
    if(info){
      info.forEach(i => {
        array.push(i.puesto)
      })
    }
    return array;
   
  }
  getToken() {
    return localStorage.getItem('tokenTgnET');
  }


  isRoles(options) {
    const roles = this.getRoles();
    let found = false;
    roles.forEach(role => {
        if (options.includes(role)) {
            found = true; 
        }
    });
    return found;
}
}







