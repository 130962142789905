<div class="container-actividad" *ngIf="(auxiliar.length>0  ||  auxiliarAvisos.length>0) && !infoIgnored">
    <h2 class='title-actividad '>{{actividad.actividad }}</h2>
    <table class="ui single line table">
      <thead>
        <tr>
          <th class="th-item">Item</th>
          <th class="center aligned">Respuesta</th>
          <th class="center aligned no-pdf-cell">Detalles</th>
          <th class="center aligned no-pdf-cell">Foto</th>
          <th class="center aligned no-pdf-cell">Observaciones</th>
          <th class="center aligned no-pdf-cell">Comentarios</th>
          <th class="center aligned no-pdf-cell">Aviso</th>
          <th class="center aligned no-pdf-cell">Observacion</th>
          <th class="center aligned no-pdf-cell">Accion a tomar</th>
        </tr>
      </thead>

        <tr *ngFor="let item of auxiliarAvisos;index as i">
            <td style="max-width: 200px !important;white-space: pre-wrap;" class="th-item td-gris "
            *ngIf="item.aviso!=='N/A' && ((item.aviso=='M1'  || item.aviso=='M2') || ((item.aviso!='M1'  || item.aviso!='M2') && item.detalles.detalle!='N/A'))">
            {{item.pregunta}} 
            </td>
            <td style="max-width: 200px !important;white-space: pre-wrap;" class="center aligned td-gris "
            *ngIf="item.aviso!=='N/A' && ((item.aviso=='M1'  || item.aviso=='M2') || ((item.aviso!='M1'  || item.aviso!='M2') && item.detalles.detalle!='N/A'))">
            <p class="negrita">
                {{item.aviso}}
            </p>
            </td>
            <td style="max-width: 200px !important;white-space: pre-wrap;" class="center aligned td-gris no-pdf-cell "
            *ngIf="item.aviso!=='N/A' && ((item.aviso=='M1'  || item.aviso=='M2') || ((item.aviso!='M1'  || item.aviso!='M2') && item.detalles.detalle!='N/A'))">

            <i *ngIf="item.detalles.detalle &&  item.detalles.detalle != 'N/A' && item.detalles.detalle != '' "
                class="eye icon font-blue pointer" (click)="showDetalles(item.detalles,item.aviso)"></i>

            <i *ngIf="(item.detalles.detalle &&  (item.detalles.detalle == 'N/A' || item.detalles.detalle == '' ) )"
                class=" exclamation triangle icon font-red pointer" (click)="showDetalles(item.detalles,item.pregunta)">
            </i>
            </td>
            <td style="max-width: 200px !important;white-space: pre-wrap;" class="center aligned td-gris no-pdf-cell"
            *ngIf="item.aviso!=='N/A' && ((item.aviso=='M1'  || item.aviso=='M2') || ((item.aviso!='M1'  || item.aviso!='M2') && item.detalles.detalle!='N/A'))">
            <i *ngIf="item.fotos" class="camera icon font-blue pointer" (click)="showFoto(item.fotos,item.Text)"></i>
            </td>

            <td style="max-width: 200px !important;white-space: pre-wrap;" class="center aligned td-gris no-pdf-cell"
            *ngIf="item.aviso!=='N/A' && ((item.aviso=='M1'  || item.aviso=='M2') || ((item.aviso!='M1'  || item.aviso!='M2') && item.detalles.detalle!='N/A'))">
            <i *ngIf="item.observaciones.lista && i==0" class="search icon pointer font-blue"
                (click)="showObservaciones(item.observaciones,item.Text)"></i>
            </td>
            <td style="max-width: 200px !important;white-space: pre-wrap;" class="center aligned  td-gris no-pdf-cell"
            *ngIf="item.aviso!=='N/A' && ((item.aviso=='M1'  || item.aviso=='M2') || ((item.aviso!='M1'  || item.aviso!='M2') && item.detalles.detalle!='N/A'))">
            <div
                class="icon-actions">

                <i class="pencil alternate icon font-gray pointer" style="margin-right: 15px;"
                (click)="newComentarioAviso(item.nro,item,item.comentarios)"></i>
            </div>
            <div class="icon-actions">
                <i *ngIf="item.comentarios > 0" class="comment alternate icon pointer font-blue" style="margin-left: 5px;"
                (click)="verComentarioAviso(item.nro,item,item.comentarios)"></i>
            </div>
            </td>
            
        </tr>
    </table>
</div>

 