import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GaleriaService } from 'src/app/core/galeria/galeria.service';
import { GalleryImage } from 'src/app/shared/models/galeryImage';
declare var $: any;

@Component({
  selector: 'app-modal-observaciones',
  templateUrl: './modal-observaciones.component.html',
  styleUrls: ['./modal-observaciones.component.scss']
})
export class ModalObservacionesComponent implements OnInit {

  public image: GalleryImage;
  gallery: GalleryImage[] = [];
  titulo: string;
  descripcion: string;
  position: number;
  spinner = false;
  info: any;
  indexInfo = 0;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { titulo: string, info: any },
  ) {
    this.info = data.info;
  }

  ngOnInit(): void {

  }

  change(i) {
    this.indexInfo = i;
  }

}
