<!--<div class="range_container">
    <div class="sliders_control">
        <span class="from">{{from}}</span>
        <span class="to">{{to}}</span>


        <input  (change)="onChange($event)" id="fromSlider" type="range" [(ngModel)]="from" min="0" max="100"/>
        <output class="bubble"></output>
        <input (change)="onChange($event)" id="toSlider" type="range"[(ngModel)]="to" min="0" max="100"/>
        <output class="bubble"></output>
    </div>
   
</div>-->




<div class="container-slider">
  <ngx-slider [(value)]="from" [(highValue)]="to" [options]="options" (userChange)="onChange()"></ngx-slider>
</div>
