import { Component, Input, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-spinner-overlay',
  templateUrl: './spinner-overlay.component.html',
  styleUrls: ['./spinner-overlay.component.scss']
})
export class SpinnerOverlayComponent implements OnInit {
  @Input() val;
  @Input() icon=false;
  @Input() index;
  @Input() color: string;
  stroke = "141.37px";
  constructor() {

  }
  ngAfterContentInit() {

  }
  ngOnInit(): void {
    var r = 22.5;
    var c = Math.PI*(r*2);
    var pct = ((100-this.val)/100)*c;
    this.stroke =pct.toString()+"px";


   // this.stroke = ((this.val * this.strokeInitial)/ 100).toString();
   
  }


}
