
<div class="ui   pagination menu">
    <a class="icon item" (click)='first()'>
        <i class="angle double left icon"></i>
    </a>
    <a class="icon item" (click)='previus()'>
        <i class="angle left icon"></i>
    </a>
     
    <a class="item" [ngClass]="(page==number_page)?'pagina-actual':''" (click)='cambiarPagina(page)' *ngFor='let page of pages'>
      
        <p *ngIf='page!=0'>{{page}}</p>
    </a>
    
    <a class="item" *ngIf='pages_total>5'>
        <p >...</p>
      </a>

    <a class="icon item" (click)='next()'>
        <i class="angle right icon"></i>
    </a>
    <a class="icon item" (click)='last()'>
        <i class="angle double right icon"></i>
    </a>
</div>