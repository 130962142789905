
<div class="" style='display: block; width: 50vw;'>
  
    <form [formGroup]="formulario" id=""  *ngIf="!spinner">
        <div style='overflow-x: auto;' style="margin-bottom: 2em;" *ngIf="numero_ot">
            <table class="ui  unstackable" style="width: 100%;">
                <thead>
                    <tr style="width: 100%; text-align-last: right;"> <i mat-dialog-close class="close icon"></i></tr>
                    <tr>
                        <th colspan="2" class="center aligned">Información auxiliar PDF</th>
                    </tr>
                    <tr>
                        <td colspan="2" class="center aligned">Agregar datos de cabecera del informe</td>
                    </tr>
                </thead>
                <tbody >
                    <tr>
                        <td colspan="2" class='label'>Región:</td>
                       
                    </tr>
                    <tr>
                        <td colspan="2">
                            <div class="ui form">
                                <textarea name="" id="" rows="1" formControlName="region" class="ng-pristine ng-valid ng-touched textArea-modal"  [attr.readonly]="!isRole || finished ? true : null"    [ngClass]="(!isRole || finished) ? 'readonly' : ''" ></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class='label'>Emitido:</td>
                       
                    </tr>
                    <tr>
                        <td colspan="2">
                            <div class="ui form">
                                <textarea name="" id="" rows="1" formControlName="emitido" class="ng-pristine ng-valid ng-touched textArea-modal" [attr.readonly]="!isRole || finished ? true : null"    [ngClass]="(!isRole || finished) ? 'readonly' : ''" ></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class='label'>Destinatarios:</td>
                       
                    </tr>
                    <tr>
                        <td colspan="2">
                            <div class="ui form">
                                <textarea name="" id="" rows="1" formControlName="destinatarios" class="ng-pristine ng-valid ng-touched textArea-modal" [attr.readonly]="!isRole || finished ? true : null"    [ngClass]="(!isRole || finished) ? 'readonly' : ''" ></textarea>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class='label'>CC:</td>
                       
                    </tr>
                    <tr>
                        <td colspan="2">
                            <div class="ui form">
                                <textarea name="" id="" rows="1" formControlName="cc" class="ng-pristine ng-valid ng-touched textArea-modal" [attr.readonly]="!isRole || finished ? true : null"    [ngClass]="(!isRole || finished) ? 'readonly' : ''" ></textarea>
                            </div>
                        </td>
                    </tr>

                </tbody>
               
            </table>
        </div>
        <div class="" >
            <button *ngIf="hayDatos" [disabled]="!isRole|| formulario.invalid || finished"
                class="ui  button  primary"
                (click)="post()">
                Actualizar
            </button>
            <button *ngIf="!hayDatos "  [disabled]="!isRole|| formulario.invalid || finished"
                class="ui  button  primary"
                (click)="post()">
                Guardar
            </button>
            <button  [disabled]="!isRole|| formulario.invalid || watermark=='true' || finished || !hayDatos" 
                class="ui  button  primary"
                (click)="finalizar()">
                Finalizar
            </button>
            <button [disabled]="!isRole || formulario.invalid" (click)="getListadoInfo()"
                class="ui right labeled icon button button-filter primary">
                <i class="download icon pointer"></i>
                Descargar PDF
            </button>
        </div>

        
        
    </form>

</div>
<app-pdf-a-descargar *ngIf="mostrarDescargar" #PDFAux id="PDFAux" [formularioValue]="formularioValue" [elementosEncontrados]="elementosEncontrados" [fecha]="fecha" [planta]="planta"></app-pdf-a-descargar>