<mat-toolbar class="toolbar-container" color="accent">

  <mat-toolbar-row>

    <span>{{title}}</span>

    <span class="toolbar-fill-space"></span>

    <button *ngIf="!isOnlyTitle() && !isTitleAndBack()" class="toolbar-button" mat-raised-button color="primary" type="submit"
            (click)="onSubmit()">
      GUARDAR
    </button>
    <button *ngIf="!isOnlyTitle()" appGoBack class="toolbar-button" mat-raised-button color="primary" type="submit">
      VOLVER
    </button>

  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="subtitle" class="alumno-name-row">
    <span class="alumno-name">{{subtitle}}</span>
  </mat-toolbar-row>

  <mat-progress-bar class="toolbar-progress-bar" *ngIf="wait" mode="indeterminate"></mat-progress-bar>

</mat-toolbar>
