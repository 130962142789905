import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../core/guards/auth.guard';
import { AdministracionDePuntajesComponent } from './pages/administracion-de-puntajes/administracion-de-puntajes.component';
import { DetalleComponent } from './pages/detalle/detalle.component';
import { AdminGuard } from 'src/app/core/guards/admin.guard';

const routes: Routes = [
  { path: 'detalle/:id/:descripcion/:state', component: DetalleComponent, canActivate: [AuthGuard] },
  { path: 'administrar-puntajes', component: AdministracionDePuntajesComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministracionRoutingModule { }
