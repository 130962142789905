import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ApiServerService } from 'src/app/core/services/api/api-server.service';
import { Service } from 'src/app/core/services/misiones/misiones.service';
import { UsuarioService } from 'src/app/core/services/usuario/usuario.service';
import { GalleryModalComponent } from 'src/app/shared/components/gallery-modal/gallery-modal.component';
import { ModelComentarioComponent } from 'src/app/shared/components/model-comentario/model-comentario.component';
import { GalleryImage } from 'src/app/shared/models/galeryImage';
import { environment } from 'src/environments/environment';
import { ModalObservacionesComponent } from '../modal-observaciones/modal-observaciones.component';
declare var $: any;
@Component({
  selector: 'app-avisos-mantenimiento',
  templateUrl: './avisos-mantenimiento.component.html',
  styleUrls: ['./avisos-mantenimiento.component.scss']
})
export class AvisosMantenimientoComponent implements OnInit {
  @Input() equipo;
  @Input() actividad;
  @Input() infoIgnored;
  mantenimiento = '¿Desea crear un aviso de mantenimiento para esta actividad?';
  id: any;
  token: any;
  rol = [];
  verAvisos = false;
  posicionAux: number;
  gallery: GalleryImage[] = [];
  arrayDelete = [];
  auxiliar = [];
  auxiliarAvisos = [];
  preguntasContains = [];
  elRef: any;
  renderer: any;
  constructor(public dialog: MatDialog, private service: Service, private apiservice: ApiServerService, private rutaActiva: ActivatedRoute, private usuarioServices: UsuarioService) {
    this.id = this.rutaActiva.snapshot.params.id;
    this.token = this.usuarioServices.getToken();
    this.rol = this.usuarioServices.getRoles();

  }

  ngOnInit(): void {
    //$('.ui.active.dimmer').css("display", "none");
   this.cargar()
  }

  cargar() {
    if (this.actividad) {
      this.actividad.preguntas.forEach(element => {
        if (element.Instruction_Id.startsWith("AvisoMtto")) {
          this.avisos(element, this.actividad.id)
        } else {
          for (var key in element.Status) {
            let data = this.buscar(element.Instruction_Id, element.Instruction_Dsc, element.Mission_Id);
            element.preguntasInfo = [];
            element.Status[key].comentarios = this.buscarComentario(this.actividad.comentarios, element);
            element.Status[key].fotos = data.fotos;
            element.Status[key].detalles = data.detalles;
            element.Status[key].observaciones = data.observaciones;
            element.preguntasInfo.push(element.Status[key])
            element.nro = this.actividad.id;
            if (!element.Instruction_Id.startsWith("Det") && !this.preguntasContains.includes(element.Status[key].Text)) {
              this.auxiliar.unshift(element)
              this.preguntasContains.push(element.Status[key].Text)
            }
          }
        }
      });
      this.auxiliar = this.ordenarInstructions(this.auxiliar)
    }
    $('#spinner-load').css("display", "none");

  }
  buscarDetAvisoMtto(result: any, id) {
    let indexDet = 0;
    let detalles = {
      detalle: null,
      persona: "",
      fecha: ""
    }
    for (var key in result.Status) {
      if (key == "AvisoMttoOtros") {
        let filter = this.actividad.mensajes.filter(d => d.Evidence_Type=="" && d.Instruction_Id == 'DetAvisoMttoOtros')
        let index = filter.length - 1;
        detalles.detalle = filter[index].Status.DetAvisoMttoOtros.Observed;
        detalles.persona = filter[index].Who[0].username;
        detalles.fecha = filter[index].When;
        indexDet++;
      }
      else
        if (key.startsWith("Det" + id)) {
          detalles.detalle = result.Status[key].Observed;
          detalles.persona = result.Who[0].username;
          detalles.fecha = result.When;
          indexDet++;
        }
    }

    return detalles;
  }
  avisos(result: any, nro: any) {
    for (var key in result.Status) {
      if (key.startsWith("AvisoMtto") && result.Status[key].Observed !== 'N/A') {
        let obj = {
          pregunta: result.Instruction_Dsc,
          aviso: result.Status[key].Observed,
          detalles: this.buscarDetAvisoMtto(result, key),
          Mission_Id: result.Mission_Id,
          Activity_Id: result.Activity_Id,
          Protocol_Id: result.Protocol_Id,
          nro: nro,
          id: key,
          observaciones: this.buscarObservacionAvisoMtto(result.Instruction_Id, result.Instruction_Dsc, result.Mission_Id),
          comentarios: this.buscarComentarioAviso(this.actividad.comentarios, result, key)
        }
        this.auxiliarAvisos.push(obj)

      }
    }

  }
  buscarObservacionAvisoMtto(instruction_id, instruction_Dsc, mission_id) {
    let observaciones: any = {}
    this.actividad.observaciones.filter(a => a.Mission_Id == mission_id).forEach(m => {
      if ((Array.isArray(m.SubjectPart) && m.SubjectPart.includes(instruction_id) || m.Instruction_Id == instruction_id)) {
        let observacion = (m.Status.Observacion)
        observaciones.lista = (!observaciones.lista) ? observaciones.lista = [] : observaciones.lista;
        observaciones.lista.push(observacion)
        observaciones.id = m.Mission_Id;
      }
    })
    return observaciones;
  }


  ordenarInstructions(array: any) {
    //Instruction_Seq
    return array.sort(function (x, y) {
      if (new Number(x.Instruction_Seq) < new Number(y.Instruction_Seq)) {
        return -1;
      }

      if (new Number(x.Instruction_Seq) > new Number(y.Instruction_Seq)) {
        return 1;
      }

      return 0;
    });
  }
  buscarComentario(lista: any, item: any): any {
    for (let key in item.Status) {
      let s = this.limpiarId(item.Status[key].Text)
      let id = item.Mission_Id + "-" + item.Activity_Id + "-" + item.Protocol_Id + "-" + key + "-" + s;
      let e = lista.filter(d => d.code == id);
      return e.length;
    }
  }
  buscarComentarioAviso(lista: any, item: any, key: any): any {
    let s = this.limpiarId(item.Instruction_Dsc)
    let id = item.Mission_Id + "-" + item.Activity_Id + "-" + item.Protocol_Id + "-" + key + "-" + s;
    let e = lista.filter(d => d.code == id);
    return e.length;

  }

  limpiarId(id) {
    return id.replaceAll("¿", "").replaceAll("?", "").replaceAll("/", "")
  }

  buscar(instruction_id, instruction_Dsc, mission_id) {
    let fotos = null;
    let observaciones: any = {}
    let detalles = null
    this.actividad.mensajes.filter(a => a.Mission_Id == mission_id).forEach(m => {
      if (Array.isArray(m.SubjectPart) && m.SubjectPart.includes(instruction_id)) {
        if (Array.isArray(m.SubjectPart) && m.SubjectPart.includes(instruction_id) && m.Status[m.Instruction_Id] && m.Status[m.Instruction_Id].Observed == "Recolectada") {
          fotos = (m.Status[m.Instruction_Id])
          fotos.id = m.Mission_Id;
        }
        if (Array.isArray(m.SubjectPart) && m.SubjectPart.includes(instruction_id) && m.Instruction_Id.startsWith("Detalle") && m.Status[m.Instruction_Id] && m.Status[m.Instruction_Id].Observed != "") {
          detalles = {}
          detalles.detalle = (m.Status[m.Instruction_Id].Observed);
          detalles.persona = m.Who[0].username;
          detalles.fecha = m.When;
        }

      }

    })

    this.actividad.observaciones.filter(a => a.Mission_Id == mission_id).forEach(m => {
      if ((Array.isArray(m.SubjectPart) && m.SubjectPart.includes(instruction_id) || m.Instruction_Id == instruction_id) && m.Evidence_Type == "AvisoDeMantenimiento") {
        let observacion = (m.Status.Observacion)
        observaciones.lista = (!observaciones.lista) ? observaciones.lista = [] : observaciones.lista;
        observaciones.lista.push(observacion)
        observaciones.id = m.Mission_Id;
      }
    })
    return {
      fotos: fotos,
      detalles: detalles,
      observaciones: observaciones
    }
  }

  showFoto(data, titulo) {
    this.gallery = [];
    let fotos = data.Evidence;
    let descripcion = data.Observed;
    var i = 0;
   
    fotos.forEach(f => {
      const image = new GalleryImage();
      image.alt = f;
      image.src = `${environment.apiUrl}/api/instance/instancia-de-prueba/mission/Mission_${data.id}/file/${f}?token=${this.token}`;
      image.position = (this.posicionAux) ? this.posicionAux : i;
      this.gallery.push(image);
      i = i + 1;
    })
    this.dialog.open(GalleryModalComponent, { panelClass: 'custom-dialog-container', data: { titulo: titulo, descripcion: descripcion, gallery: this.gallery, position: 0 } });
  }

  showObservaciones(data, titulo) {
    let info = []
    data.lista.forEach(item => {
      let observacion: any = {}
      observacion.gallery = [];
      let fotos = item.Evidence;
      observacion.descripcion = item.Observed;
      var i = 0;
      fotos.forEach(f => {
        const image = new GalleryImage();
        image.alt = f;
        image.src = `${environment.apiUrl}/api/instance/instancia-de-prueba/mission/Mission_${data.id}/file/${f}?token=${this.token}`;
        image.position = (this.posicionAux) ? this.posicionAux : i;
        observacion.gallery.push(image);
        i = i + 1;
      })
      info.push(observacion)

    })

    this.dialog.open(ModalObservacionesComponent, { panelClass: 'custom-dialog-container', data: { info: info } });
  }
  showDetalles(data, titulo) {
    if (data) {
      this.dialog.open(ModelComentarioComponent, { panelClass: 'custom-dialog-container', data: { detalle: data, type: "detalle" } });
    }

  }
  verComentario(nro, item, comentarios) {

    for (let key in item.Status) {
      let s = this.limpiarId(item.Status[key].Text)
      let id = item.Mission_Id + "-" + item.Activity_Id + "-" + item.Protocol_Id + "-" + key + "-" + s;
      this.dialog.open(ModelComentarioComponent, { panelClass: 'custom-dialog-container', data: { nro: nro, _id: id, comentarios: comentarios, type: "ver" } });
    }
  }
  verComentarioAviso(nro, item, comentarios) {

    let s = this.limpiarId(item.pregunta)
    let id = item.Mission_Id + "-" + item.Activity_Id + "-" + item.Protocol_Id + "-" + item.id + "-" + s;
    this.dialog.open(ModelComentarioComponent, { panelClass: 'custom-dialog-container', data: { nro: nro, _id: id, comentarios: comentarios, type: "ver" } });

  }

  newComentario(nro, item, comentarios) {
    for (let key in item.Status) {
      let s = this.limpiarId(item.Status[key].Text)
      let id = item.Mission_Id + "-" + item.Activity_Id + "-" + item.Protocol_Id + "-" + key + "-" + s;
      const dialogRef = this.dialog.open(ModelComentarioComponent, { panelClass: 'custom-dialog-container', data: { nro: nro, _id: id, comentarios: comentarios, type: "crear" } });
      dialogRef.afterClosed().subscribe(() => {
        let element = this.actividad.preguntas.find(d => d._id == item._id);
        for (var key in element.Status) {
          this.apiservice.getComentario(id).subscribe(res => {
            element.Status[key].comentarios = res.commentsDTOs.length;
          })
        }
      });
    }
  }
  newComentarioAviso(nro, item, comentarios) {
    let s = this.limpiarId(item.pregunta)
    let id = item.Mission_Id + "-" + item.Activity_Id + "-" + item.Protocol_Id + "-" + item.id + "-" + s;
    const dialogRef = this.dialog.open(ModelComentarioComponent, { panelClass: 'custom-dialog-container', data: { nro: nro, _id: id, comentarios: comentarios, type: "crear" } });
    dialogRef.afterClosed().subscribe(() => {
      let element = this.auxiliarAvisos.find(d => d.id == item.id);
      this.apiservice.getComentario(id).subscribe(res => {
        element.comentarios = res.commentsDTOs.length;
      })
    });
  }

  newAviso(nro, item, comentarios) {
    for (let key in item.Status) {
      let s = this.limpiarId(item.Status[key].Text)
      let id = item.Mission_Id + "-" + item.Activity_Id + "-" + item.Protocol_Id + "-" + key + "-" + s;
      const dialogRef = this.dialog.open(ModelComentarioComponent, { panelClass: 'custom-dialog-container', data: { nro: nro, _id: id, comentarios: comentarios, type: "crear" } });
      dialogRef.afterClosed().subscribe(() => {
        let element = this.actividad.preguntas.find(d => d._id == item._id);
        for (var key in element.Status) {
          this.apiservice.getComentario(id).subscribe(res => {
            element.Status[key].comentarios = res.commentsDTOs.length;
          })
        }
      });
    }
  }
  newObservacion(nro, item, comentarios) {
    for (let key in item.Status) {
      let s = this.limpiarId(item.Status[key].Text)
      let id = item.Mission_Id + "-" + item.Activity_Id + "-" + item.Protocol_Id + "-" + key + "-" + s;
      const dialogRef = this.dialog.open(ModelComentarioComponent, { panelClass: 'custom-dialog-container', data: { nro: nro, _id: id, comentarios: comentarios, type: "crear" } });
      dialogRef.afterClosed().subscribe(() => {
        let element = this.actividad.preguntas.find(d => d._id == item._id);
        for (var key in element.Status) {
          this.apiservice.getComentario(id).subscribe(res => {
            element.Status[key].comentarios = res.commentsDTOs.length;
          })
        }
      });
    }
  }
  newAccion(nro, item, comentarios) {
    for (let key in item.Status) {
      let s = this.limpiarId(item.Status[key].Text)
      let id = item.Mission_Id + "-" + item.Activity_Id + "-" + item.Protocol_Id + "-" + key + "-" + s;
      const dialogRef = this.dialog.open(ModelComentarioComponent, { panelClass: 'custom-dialog-container', data: { nro: nro, _id: id, comentarios: comentarios, type: "crear" } });
      dialogRef.afterClosed().subscribe(() => {
        let element = this.actividad.preguntas.find(d => d._id == item._id);
        for (var key in element.Status) {
          this.apiservice.getComentario(id).subscribe(res => {
            element.Status[key].comentarios = res.commentsDTOs.length;
          })
        }
      });
    }
  }
}
