<div class="" style="display: flex; justify-content: space-between;">
    <div [style.min-width]="'200px'" >
        <h3 class='title-style mt-3'>{{title}}</h3>
        <h2 class='negrita ' style='margin-top: 0px!important;margin-left: 0px!important;'>Estado:<span
                class='font-gray' style='margin-top: 0px!important;padding-left: 5px;'>{{estado}}</span></h2>
        <h2 class='negrita ' style='margin-top: 0px!important;margin-left: 0px!important;'>Puntaje:<span
                class='font-gray' style='margin-top: 0px!important;padding-left: 5px;'>{{score}}</span></h2>
    </div>

    <div class="ui dropdown manu-dropdown button primary" style="font-weight:bold; padding:17.5px 17.5px 17.5px 1px; height: min-content;">
       
        Opciones
        <i class="dropdown icon"></i>
        <div class="menu">
          <div role="link" *ngIf="mostrarBotonDescargarPDF" class="item" (click)="descargarPDF()">
            <span class=""  >  
                <i class="download icon pointer"></i>
                DESCARGAR PDF
               
            </span>
          </div>
          
          <div role="link" class="item" (click)="copyURL()">
            <span class=""  >  
                <i class="clone outline pointer
                icon"></i>
                COPIAR LINK
               
            </span>
          </div>

          <div     role="link" class="item"  (click)="openModalInformacionAux()">
            <span class="" >  
                <i class="download icon pointer"></i>
                INFORMACIÓN AUXILIAR PDF
            </span>
          </div>

          <div role="link" class="item" (click)="openModal()" >
            <span class=""  >
                <i class="list pointer
                icon"></i>
                RESUMEN DE AVISOS  
               
            </span>
          </div>
        </div>
      </div>
    
   <!--  <div class=""  style="width: 100%; flex-wrap: nowrap; text-align: right;" >
        <button id='b1' *ngIf='!id_component && groupBtn1'
            class="ui right labeled icon button  primary no-pdf" (click)="descargarPDF()"
            style='vertical-align:inherit;margin-top:1.5em;'>
            <i class="download icon pointer"></i>

            DESCARGAR PDF

        </button>

        <button id='b2' class="ui right labeled icon button  primary no-pdf" id='copiarLink' *ngIf="groupBtn1" (click)="copyURL()"
            style='vertical-align:inherit;margin-top:1.5em;margin-bottom:.4em;'>
            <i class="clone outline pointer
                icon"></i>
            COPIAR LINK

        </button>
        <button id='b3' class="ui right labeled icon button  primary no-pdf" id='copiarLink'  *ngIf="groupBtn2" (click)="openModalInformacionAux()"
            style='vertical-align:inherit;margin-top:1.5em;margin-bottom:.4em;'>
            <i class="download icon pointer"></i>
            INFORMACIÓN AUXILIAR PDF

        </button>

        <button id='b3' class="ui right labeled icon button  primary no-pdf" id='copiarLink'  *ngIf="groupBtn2" (click)="openModal()"
            style='vertical-align:inherit;margin-top:1.5em;margin-bottom:.4em;'>
                <i class="list pointer
                icon"></i>

            RESUMEN DE AVISOS

        </button>

    </div> -->



</div>

<div class="ui divider no-pdf-flex"></div>